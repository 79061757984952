// React
import React, { useState, useEffect } from 'react';

// bootstrap
import Table from "react-bootstrap/Table";
import Container from 'react-bootstrap/Container';
import "bootstrap/dist/css/bootstrap.css";

import { useSnackbar } from 'notistack';

// MobX
import { observer } from 'mobx-react-lite';

// Blockly
import Blockly from 'blockly/core';

// VMT API
// import { constructions } from 'utils/VApi';

// VMT components
import TreeComponent from 'components/TreeComponent';
import DialogModal from 'components/Modals/DialogModal';
import { CRUD, TreeActions, Types, ContextMenuItems, InitXmls } from 'utils/const';
import PopupMenu, { MenuItem, MenuSeparator } from 'components/PopupMenu';

// CSS
import { css } from "aphrodite";

// VMT styles
import styles from '../Styles';

// VMT stores
import { constructionDataStore, selectedConstructionInfoStore, useStore } from 'stores';
import { useAppContext } from 'app-context';

const initialState = {
    x: -1,
    y: -1,
    selectedItem: null
};

export const ConstructionTreeComponent = observer((/*props*/) => {
    console.log('<ConstructionTreeComponent>');
    const { api, store } = useAppContext();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteModalData, setDeleteModalData] = useState([]);
    const [itemListToDelete, setItemListToDelete] = useState(<></>);
    const [selectedTreeItem, setSelectedTreeItem] = useState(null);
    const [currentNode, setCurrentNode] = useState(null);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [menuXY, setMenuXY] = useState(initialState);
    const [isCtxMenu, _setCtxMenu] = useState(false);
    const isCtxMenuRef = React.useRef(isCtxMenu);
    const setCtxMenu = data => {
        isCtxMenuRef.current = data;
        _setCtxMenu(data);
    };

    const [dataToProcess, setDataToProcess] = useStore(constructionDataStore);
    const [constructionInfo, setConstructionInfo] = useStore(selectedConstructionInfoStore);

    const { enqueueSnackbar/*, closeSnackbar*/ } = useSnackbar();


    const openDeleteModal = () => setShowDeleteModal(true);
    const closeDeleteModal = () => setShowDeleteModal(false);

    const apiCreateConstructionTreeNode = (treeNodeJson) => {
        api.constructionTree.create(treeNodeJson);
    };

    const apiConstructionsCRUD = async (action, constrId, treeNode, dataToUpdate = null) => {
        switch (action) {
            case CRUD.Create:
                console.log('constructions.create');
                const newConstruction = await api.construction.create(dataToUpdate);
                if (newConstruction) {
                    // add construction to the construction tree directory
                    const newTreeNodeItem = {
                        parent_id: currentNode.id,
                        type: Types.ITEM,
                        text: newConstruction.workspace_name,
                        data: {
                            reference_id: newConstruction.id,
                        }
                    };
                    console.log(newTreeNodeItem);
                    apiCreateConstructionTreeNode(newTreeNodeItem);
                    enqueueSnackbar('Construction was successfully created', { variant: 'success', persist: false });
                }
                else {
                    enqueueSnackbar('Could not create construction', { variant: 'error', persist: true });
                }
                break;
            case CRUD.Update:
                console.log('constructions.update');
                const updatedConstruction = await api.construction.update(constrId, dataToUpdate);
                if (!updatedConstruction) {
                    enqueueSnackbar('Could not update construction', { variant: 'error', persist: true });
                }
                break;
            default:
                break;
        }
    };

    const apiUpdateConstructionTree = async (action, id, dataToUpdate) => {
        const prevNodeText = store.constructionTree.nodesById.get(id).text;
        const node = await api.constructionTree.update(id, dataToUpdate);
        if (node) {
            console.log('api.constructionTree.update', node);
            if (node.type === Types.ITEM && dataToUpdate?.text && dataToUpdate.text !== prevNodeText) {
                // update Constrcution record
                const constructionId = node.data.reference_id;
                apiConstructionsCRUD(CRUD.Update, constructionId, null, { workspace_name: dataToUpdate.text });
            }
        }
        else {
            enqueueSnackbar('Could not update node', { variant: 'error', persist: true });
        }
    };

    const onTreeItemClick = (itemData) => {
        console.log('Tree item');
        console.log(itemData);
        console.log('----------------');

        if (itemData.type === Types.ITEM) {
            console.log(`Selected constr. id: ${itemData.data.reference_id}`);
            const constructionPath = 'N/A'; //getConstructionPath(itemData);
            setConstructionInfo({ id: itemData.data.reference_id, path: constructionPath });
        }
        else {
            console.log(`Selected constr. id: NULL`);
            setConstructionInfo(null);
        }
        setCurrentNode(itemData);
    };

    const getAllChildItems = (parentNode) => {
        let childrenToDelete = []
        parentNode.children.forEach(nodeId => {
            const node = store.constructionTree.nodesById.get(nodeId);
            if (node) {
                childrenToDelete.push(node);
                if (node.children.length > 0) {
                    const subNodes = getAllChildItems(node);
                    childrenToDelete.push(...subNodes);
                }
            }
        });
        return childrenToDelete;
    };

    const onTreeChange = (itemData, action) => {
        console.log('onTreeChange: ' + action);
        console.log(itemData);
        console.log('----------------');

        switch (action) {
            case TreeActions.Rename:
                apiUpdateConstructionTree(TreeActions.Rename, itemData.id, { text: itemData.text });
                break;
            case TreeActions.Move:
                // change parent_id
                apiUpdateConstructionTree(TreeActions.Move, itemData.id, { parent_id: itemData.parent_id });
                break;
            case TreeActions.Delete:
                // prepare list of item to delete
                let itemsToDelete = itemData?.parent_id ? [itemData] : [];
                if (itemData.type === Types.NODE) {
                    const childrenToDelete = getAllChildItems(itemData);
                    console.log(childrenToDelete);
                    itemsToDelete = [...itemsToDelete, ...childrenToDelete];
                }
                if (itemsToDelete.length === 0) return;

                const itemsToDeleteJSX = itemsToDelete.map(node => {
                    return (
                        <tr key={node.id} style={{ fontWeight: node.type === Types.ITEM ? 'bold' : '' }}>
                            <td>{node.text}</td>
                            <td>{node.type === Types.NODE ? 'Node' : 'Construction'}</td>
                        </tr>
                    );
                });
                const tableJsx =
                    <>
                        Following items will be deleted
                        <Container className={css(styles.modalScrollingPane)}>
                            {/* variant="dark" */}
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {itemsToDeleteJSX}
                                </tbody>
                            </Table>
                        </Container>
                    </>;
                setItemListToDelete(tableJsx);
                setDeleteModalData(itemsToDelete);
                openDeleteModal();
                break;
            default:
                break;
        }
    };

    // data "porocessor"
    useEffect(() => {
        if (dataToProcess === null) return;
        console.log('Process data for constr. id:', constructionInfo);
        console.log(dataToProcess);
        if (constructionInfo === null) { // new constructon
            apiConstructionsCRUD(CRUD.Create, null, null, dataToProcess);
        }
        else { // update construction
            apiConstructionsCRUD(CRUD.Update, constructionInfo.id, null, dataToProcess);
        }
        // reset the data as it's been processed
        setDataToProcess(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataToProcess, constructionInfo/*, apiConstructionsCRUD,*/, setDataToProcess]);

    const modalHandleDelete = async () => {
        console.log('modalHandleDelete', deleteModalData);

        const nodeIdsToDelete = deleteModalData.map(node => node.id);
        const constrIdsToDelete = deleteModalData
            .filter(node => node.type === Types.ITEM)
            .map(item => item.data.reference_id);
        console.log('nodeIdsToDelete', nodeIdsToDelete);

        const deletedNodes = await api.constructionTree.delete(nodeIdsToDelete);
        console.log('constructionDir.delete', deletedNodes);
        if (deletedNodes.length !== 0) {
            // reset the current construction id if it was selected
            if (constructionInfo !== null && constrIdsToDelete.includes(constructionInfo.id)) {
                setConstructionInfo(null);
            }
            const childNode = deleteModalData[0];
            const parentNode = store.constructionTree.nodesById.get(childNode.parent_id);
            setSelectedTreeItem(parentNode);
            // clear the data to delete
            setDeleteModalData([]);
        }
        else {
            enqueueSnackbar('Could not update construction', { variant: 'error', persist: true });
        }
        closeDeleteModal();
    };

    const onContextMenu = (posX, posY, item) => {
        setMenuXY({ x: posX, y: posY, selectedItem: item });
        setCtxMenu(true);
    };

    const onEditMode = ({ itemData, isEditMode }) => {
        console.log('onEditMode', { itemData, isEditMode });
        isEditMode ? setItemToEdit(itemData) : setItemToEdit(null);
    };

    const onMenuItemSelect = (menuItemText) => {
        console.log('onMenuItemSelect', menuItemText);
        console.log(menuXY.selectedItem);
        const treeNode = menuXY.selectedItem;
        switch (menuItemText) {
            case ContextMenuItems.NewNode:
                apiCreateConstructionTreeNode({
                    parent_id: treeNode.id,
                    type: Types.NODE,
                    text: 'New Node #1'
                    // no data.reference_id
                });
                break;
            case ContextMenuItems.RenameNode:
                setItemToEdit(menuXY.selectedItem);
                break;
            case ContextMenuItems.DeleteNode:
                onTreeChange(menuXY.selectedItem, TreeActions.Delete);
                break;
            case ContextMenuItems.NewConstruction:
                var xml = Blockly.Xml.textToDom(InitXmls.WDIO('New Construction'));
                // Create a headless workspace.
                var headlessWorkspace = new Blockly.Workspace();
                Blockly.Xml.domToWorkspace(xml, headlessWorkspace);
                const xmlDom = Blockly.Xml.workspaceToDom(headlessWorkspace);
                const blocks = headlessWorkspace.getTopBlocks(false);
                let baseTestBlock = blocks.find(block => block.type === 'base_test');
                const itemData = menuXY.selectedItem;
                setConstructionInfo(null);
                setCurrentNode(itemData);
                setDataToProcess({
                    workspace_id: baseTestBlock.id,
                    workspace_name: baseTestBlock.getFieldValue('TEST_NAME').trim(),
                    workspace_xml: Blockly.Xml.domToText(xmlDom),
                    bricks: [],
                    controls: []
                });
                break;
            case ContextMenuItems.NodeDetails:
                break;
            default:
                break;
        }
    };

    const isNodeSelected = () => menuXY?.selectedItem?.type === Types.NODE;

    return (
        <>
            <TreeComponent
                tree={store.constructionTree.nodes}
                onItemClick={onTreeItemClick}
                onTreeChange={onTreeChange}
                selectedItem={selectedTreeItem}
                onContextMenu={onContextMenu}
                onEditMode={onEditMode}
                editNode={itemToEdit}
            />
            <PopupMenu
                // idx={ctxMenuIdx}
                posX={menuXY.x}
                posY={menuXY.y}
                onSelect={onMenuItemSelect}
            >
                {isNodeSelected() ? <MenuItem text={ContextMenuItems.NewNode} /> : <></>}
                {isNodeSelected() ? <MenuItem text={ContextMenuItems.NewConstruction} /> : <></>}
                <MenuItem text={ContextMenuItems.RenameNode} />
                <MenuItem text={ContextMenuItems.DeleteNode} />
                <MenuSeparator />
                <MenuItem text={ContextMenuItems.NodeDetails} disabled />
            </PopupMenu>
            {/* Delete constructions/nodes modal*/}
            <DialogModal
                title='Deleting...'
                show={showDeleteModal}
                handleClose={closeDeleteModal}
                primaryBtnLabel='Delete'
                onPrimary={modalHandleDelete}
            >
                {itemListToDelete}
                Are you sure?
            </DialogModal>
        </>
    );
});