// React
import { useState, useEffect } from 'react';

import CodePrettifier, { vmtLogLanguage, vmtLogTheme } from 'components/CodePrettifier';

// VMT common
import { Components } from 'utils/const'

import { dataBusStore, useStore } from 'stores';

export const ExecutionLogCodePrettifier = () => {
    const [logContentToScroll, setLogContentToScroll] = useState('');
    const [consoleText, setConsoleText] = useState('');
    const [dataBus] = useStore(dataBusStore);

    useEffect(() => {
        if (dataBus?.to === Components.V_APP.key) {
            if (dataBus.from === Components.LOCAL_RUNNER.key) {
                switch (dataBus.content.status) {
                    case 'Error':
                        setConsoleText('[LOCAL RUNNER IS NOT DETECTED]\n');
                        break;
                    // case 'Close':
                    //     setIsExecuting(false);
                    //     break;
                    case 'Running':
                        setConsoleText(dataBus.content.data);
                        break;
                    case 'Connected':
                        setConsoleText('VMT_CLEAR_EXECUTION_LOG');
                        break;
                    default:
                        break;
                }
            }
            else if (dataBus.from === Components.BASEMENT.key) {
                setLogContentToScroll(dataBus.content.blockId);
            }
        }
    }, [dataBus]);

    return (
        <CodePrettifier
            //code={consoleText}
            lineToAppend={consoleText}
            contentToScroll={logContentToScroll}
            language={vmtLogLanguage}
            theme={vmtLogTheme}
            options={{
                readOnly: true,
                lineNumbers: 'off',
                glyphMargin: false,
                folding: false,
                // Undocumented, see https://github.com/Microsoft/vscode/issues/30795#issuecomment-410998882
                lineDecorationsWidth: 0,
                lineNumbersMinChars: 0,
                minimap: {
                    enabled: false,
                }
            }}
        />
    );
}