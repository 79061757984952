import {
    action,
    computed,
    makeObservable,
    observable,
    // ObservableMap,
} from 'mobx';

import Construction from 'models/construction';
import IConstruction from 'types/construction';
import AppStore from 'stores/app';

export default class ConstructionStore {

    @observable constructionById = new Map<string, IConstruction>();
    @observable lastUpdatedConstructionId: string | null = null;


    constructor(private store: AppStore) {
        makeObservable(this);
    }

    @action load(constructions: IConstruction[]) {
        this.constructionById.clear();
        constructions.forEach((construction) => this.constructionById.set(construction.id, new Construction(this.store, construction)));
    }

    @action add(construction: IConstruction) {
        this.constructionById.set(construction.id, construction);
        this.lastUpdatedConstructionId = construction.id;
    }

    @action update(construction: IConstruction) {
        this.add(construction);
    }

    @action delete(id: string): IConstruction | undefined {
        const construction = this.byId(id);
        this.constructionById.delete(id);
        return construction;
    }

    @computed get size (): Number {
        return this.constructionById.size;
    }

    byId(id: string): IConstruction | undefined {
        return this.constructionById.get(id);
    }
}