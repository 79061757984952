import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Button = styled('button')`
    position: fixed;
    color: #ccc;
    background-color: Transparent;
    border: 2px solid #424242;
    z-index: 1000;
    padding: 3px;
    border-radius: 4px;
    font-family: monospace;
    font-size: larger;
    &:hover {
        background-color: grey;
    }
`;

const FloatingButton = ({ x, y, text, onClick }) => {
    const [buttonRef, setButtonRef] = useState(null);

    useEffect(() => {
        if (buttonRef) {
            buttonRef.style.left = `${x - buttonRef.clientWidth}px`;
            buttonRef.style.top = `${y}px`;
        }
    }, [x, y, buttonRef]);

    return (
        <Button ref={setButtonRef} onClick={onClick}>{text}</Button>
    );
};

export default FloatingButton;
