import axios from 'axios';
import { apiEndpointStore } from '../stores';

const API_BASE_URL = apiEndpointStore.endpoint;
const LOGOUT_URL = API_BASE_URL + '/logout';

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.validateStatus = (status) => {
    return status >= 200 && status < 499; // default
};

// axios.interceptors.response.use((response) => response, (error) => {
//     if (error.response.status === 401) {
//         console.log('>>> AXIOS 401 <<<');
//         auth()
//             .logout()
//             .then(
//                 (res) => {
//                     console.log('logout', res);
//                     auth()
//                         .login()
//                         .then(
//                             (res) => {
//                                 const json = res.data;
//                                 if (json.redirect) {
//                                     window.location.href = json.url;
//                                 }
//                             });
//                 }
//             );
//         return;
//     }
//     return error;
// });

// export const bricks = () => {
//     const url = '/bricks';
//     return {
//         // getAll: () => axios.get('https://my-json-server.typicode.com/sampleu/vavanyaAPI/bricks'),
//         getAll: () => axios.get(url),
//         create: (toCreate) => axios.post(url, toCreate),
//         update: ({ id, toUpdate }) => axios.patch(`${url}/${id}`, toUpdate),
//         getOne: ({ id }) => axios.get(`${url}/${id}`),
//         delete: ({ id }) => axios.delete(`${url}/${id}`)
//     };
// };

// export const brickDirectory = () => {
//     const url = '/brick_dir';
//     return {
//         get: () => axios.get(url),
//         create: (toCreate) => axios.post(`${url}/node`, toCreate),
//         update: ({ id, toUpdate }) => axios.patch(`${url}/node/${id}`, toUpdate),
//         delete: (nodeIds) => axios.delete(`${url}/nodes`, { params: { nodeIds } }),
//         // getOne: ({ id }) => axios.get(`${url}/${id}`),
//         // delete: ({ id }) =>  axios.delete(`${url}/${id}`)
//     };
// };

export const constructions = () => {
    const url = '/constructions';
    return {
        // getAll: () => axios.get(url),
        // create: (toCreate) => axios.post(url, toCreate),
        // update: ({ id, toUpdate }) => axios.patch(`${url}/${id}`, toUpdate),
        // getOne: ({ id }) => axios.get(`${url}/${id}`),
        // delete: ({ id }) => axios.delete(`${url}/${id}`),
        bricksUsage: (brickIds) => axios.get(`${url}/bricks_usage`, { params: { brickIds } }),
    };
};

// export const constructionDirectory = () => {
//     const url = '/construction_dir';
//     return {
//         get: () => axios.get(url),
//         create: (toCreate) => axios.post(`${url}/node`, toCreate),
//         update: ({ id, toUpdate }) => axios.patch(`${url}/node/${id}`, toUpdate),
//         // getOne: ({ id }) => axios.get(`${url}/${id}`),
//         // delete: ({ id }) => axios.delete(`${url}/node/${id}`)
//         delete: (nodeIds) => axios.delete(`${url}/nodes`, { params: { nodeIds } }),
//     };
// };

export const user = () => {
    return {
        anonymize: () => axios.post('/user/delete_acc')
    };
};

export const auth = () => {
    return {
        tokenRefresh: () => axios.get('/auth/refresh'),
        login: () => axios.get('/login'),
        // could not make the 'NO-CORS' call with 'axios' API
        // that is why 'logout' is implemented with 'fetch' API
        logout: () => fetch(LOGOUT_URL,
            {
                mode: 'no-cors',
                cache: 'no-cache',
                credentials: 'include',
                redirect: 'follow'
            })
            .catch(error => {
                console.error('>>Error(GET):', error);
            })
    };
};
