import {
    action,
    computed,
    makeObservable,
    observable,
    // ObservableMap,
} from 'mobx';

import Brick from 'models/brick';
import IBrick from 'types/brick';
import AppStore from 'stores/app';

export default class BrickStore {

    @observable bricksById = new Map<string, IBrick>();
    @observable lastUpdatedBrickId: string | null = null;
    // private bricksById = observable.map<string, IBrick>();
    // bricksById = new ObservableMap<string, IBrick>();

    constructor(private store: AppStore) {
        makeObservable(this);
    }

    @action load(bricks: IBrick[]) {
        this.bricksById.clear();
        bricks.forEach((brick) => this.bricksById.set(brick.id, new Brick(this.store, brick)));
    }

    @action add(brick: IBrick) {
        this.bricksById.set(brick.id, brick);
        this.lastUpdatedBrickId = brick.id;
    }

    @action update(brick: IBrick) {
        this.add(brick);
    }

    @action delete(id: string): IBrick | undefined {
        const brick = this.byId(id);
        this.bricksById.delete(id);
        return brick;
    }

    @computed get size (): Number {
        return this.bricksById.size;
    }

    byId(id: string): IBrick | undefined {
        return this.bricksById.get(id);
    }
}