// React
import React, { useState } from 'react';

// MUI
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Tooltip from '@mui/material/Tooltip';
// MUI icons
import ApiIcon from '@mui/icons-material/Api';
import LogoutIcon from '@mui/icons-material/Logout';
import GroupsIcon from '@mui/icons-material/Groups';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import EditRoadOutlinedIcon from '@mui/icons-material/EditRoadOutlined';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';

/*
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as ConstructionIcon } from 'construction.svg';
// Usage example:
<SvgIcon component={ConstructionIcon} style={{ color: 'red' }} /> 
*/

// VMT
import InputModal from 'components/Modals/InputModal';
import AboutModal from 'components/Modals/AboutModal';

import { URIs } from 'utils/const';
import { apiEndpointStore, /*addInMenuStore,*/ useStore } from 'stores';
import { useAppContext } from "app-context";

const VISUAL_MODES = Object.freeze({
    Construction: {
        value: 'construction',
        display: 'Construction'
    },
    Streets: {
        value: 'streets',
        display: 'Streets'
    },
});

export const HeaderComponent = (props) => {

    const { /*mainOnSelect,*/ logoutCallback, /* onToolboxUpdate, onConvert, currentPage,*/ userObject } = props;
    const [showInputModal, setShowInputModal] = useState(false);
    const [showAboutModal, setShowAboutModal] = useState(false);
    const [visualMode, setVisualMode] = useState(VISUAL_MODES.Construction.value);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [apiEndpoint, setApiEndpoint] = useStore(apiEndpointStore);
    const { session } = useAppContext();

    const handleVisualModeChange = (event, newVisualMode) => {
        if (newVisualMode === null) return;
        session.setLayout(newVisualMode);
        setVisualMode(newVisualMode);
    };

    const toggleColorMode = () => {
        session.setTheme((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    }

    const handleMenuAnchorElementChange = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const changeApiEndpoint = () => {
        setMenuAnchorEl(null);
        setShowInputModal(true);
    };

    const logout = () => {
        setMenuAnchorEl(null);
        logoutCallback();
    };



    return (
        <>
            <AppBar color="primary" position="static" sx={{ height: 'min-content' }} >
                <Toolbar variant="dense">
                    <IconButton
                        size="large"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        sx={{ mr: 2 }}
                        onClick={() => setShowAboutModal(true)}
                    >
                        {/* <MenuIcon /> */}
                        {/* <LogoSVG /> */}
                        {/* <Icon /> */}
                        {/* <Icon classes={{ root: classes.iconRoot }}> */}
                        <img src="/favicon-32x32.png" alt='vavanya logo' />
                        {/* <img src="/favicon-16x16.png" /> */}
                        {/* </Icon> */}
                    </IconButton>
                    <Typography variant="body1" component="div" sx={{ fontSize: '0.75em', flexGrow: 1 }} >
                        #VMT = () =&gt; &#123; Vavanya-Means-Test &#125;
                    </Typography>

                    <ToggleButtonGroup
                        color="primary"
                        value={visualMode}
                        exclusive
                        onChange={handleVisualModeChange}
                        sx={{ flexGrow: 2 }}
                        aria-label="Visual Mode"
                    >

                        <ToggleButton value={VISUAL_MODES.Construction.value}>
                            <Tooltip title={VISUAL_MODES.Construction.display} placement="bottom-end">
                                {/* {VISUAL_MODES.Construction.display} */}
                                <BusinessOutlinedIcon />
                            </Tooltip>
                        </ToggleButton>

                        <ToggleButton value={VISUAL_MODES.Streets.value} placement="bottom-end">
                            <Tooltip title={VISUAL_MODES.Streets.display}>
                                {/* {VISUAL_MODES.Streets.display} */}
                                <EditRoadOutlinedIcon />
                            </Tooltip>
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <IconButton sx={{ ml: 1 }} color="inherit" onClick={toggleColorMode}>
                        {session.theme === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
                        {/* <Brightness7Icon /> */}
                    </IconButton>
                    {/* User menu */}
                    <>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenuAnchorElementChange}
                            color="inherit"
                        >
                            <Typography variant="h6" component="div" >
                                {userObject.userName}
                                &nbsp;
                            </Typography>
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={menuAnchorEl}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(menuAnchorEl)}
                            onClose={handleMenuClose}
                        >
                            <MenuItem component="a" href={URIs.IDP}>
                                <ListItemIcon>
                                    <ManageAccountsIcon />
                                </ListItemIcon>
                                <ListItemText>Account Management</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={changeApiEndpoint}>
                                <ListItemIcon>
                                    <ApiIcon />
                                </ListItemIcon>
                                <ListItemText>API Endpoint</ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                component="a"
                                href={`${URIs.Fofum}/login`}
                                target="_blank"
                                rel="noopener"
                            >
                                <ListItemIcon>
                                    <GroupsIcon />
                                </ListItemIcon>
                                <ListItemText>Support Forum</ListItemText>
                            </MenuItem>
                            <MenuItem disabled>
                                <ListItemIcon>
                                    <LiveHelpIcon />
                                </ListItemIcon>
                                <ListItemText>Get Started / FAQ</ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={logout}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText>Log Out</ListItemText>
                            </MenuItem>
                        </Menu>
                    </>
                </Toolbar>
            </AppBar>
            <InputModal
                title='Enter new API endpoint URL'
                show={showInputModal}
                defaultValue={apiEndpoint || ''}
                handleClose={() => setShowInputModal(false)}
                onEnter={(newApiEndpoint) => { if (newApiEndpoint !== apiEndpoint) setApiEndpoint(newApiEndpoint); }}
            />
            <AboutModal
                show={showAboutModal}
                handleClose={() => setShowAboutModal(false)}
            />
        </>
    );
}

// export default HeaderComponent;