import React, { useState, useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { ThemeProvider } from "@mui/material/styles";
// import CssBaseline from '@mui/material/CssBaseline';

import qs from 'qs';

import AppContext, { api, store, useSessionContext } from "./app-context";

import VApp from 'components/VApp';
import IdleMonitor from 'components/IdleMonitor';

import { auth, user as userApi } from 'utils/VApi';
import { apiEndpointStore, useStore } from 'stores';
import { themeLight, themeDark } from 'components/MuiThemes';
import LocalRunner from 'components/LocalRunner';

import { URIs } from 'utils/const';

const AuthWrapper = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isDeletingAccount, setIsDeletingAccount] = useState(false);
    const [, setApiEndpoint] = useStore(apiEndpointStore);
    const [user, setUser] = useState();
    // session's hooks
    // const [theme, setTheme] = useState('dark');
    const { theme, setTheme, layout, setLayout } = useSessionContext();

    const redirect = (url) => window.location.href = url;


    // useEffect(() => {
    //     console.log('LAYOUT changed', layout);
    // }, [layout]);

    useEffect(() => {
        if (isAuthenticated && isDeletingAccount) {
            userApi()
                .anonymize()
                .then(({ data }) => {
                    console.log('ANONYMIZED', data);
                    if (data === 'deleted') {
                        console.log('REDIRECT TO IDP - SUCCESS');
                        // redirect to https://myaccount.vavanya.com/?accDeleteStatus=success
                        redirect(`${URIs.IDP}/?accDeleteStatus=success`);
                    }
                    else {
                        console.log('REDIRECT TO IDP - FAIL');
                        // redirect to https://myaccount.vavanya.com/?accDeleteStatus=fail
                        redirect(`${URIs.IDP}/?accDeleteStatus=fail`);
                    }
                })
        }
    }, [isAuthenticated, isDeletingAccount]);

    useEffect(() => {
        let queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true })
        // use API endpoint provided via URL query
        if (queryString.api_endpoint !== undefined) {
            setApiEndpoint(queryString.api_endpoint);
        }
        else {
            window.localStorage.removeItem('API_ENDPOINT');
        }
        // deleteAccount
        queryString = qs.parse(window.location.search, { ignoreQueryPrefix: true })
        if (queryString.deleteAccount !== undefined) {
            setIsDeletingAccount(true);
        }

        auth()
            .tokenRefresh()
            .then(
                (res) => {
                    console.log(res);
                    if (res.status < 399) {
                        setUser(res.data);
                        setIsAuthenticated(true);
                    }
                    setLoading(false);
                });
    }, [setApiEndpoint]);

    const loginWithRedirect = () => {
        auth()
            .login()
            .then(({ data }) => data.redirect && redirect(data.url));
    };

    const logout = () => {
        auth()
            .logout()
            .then(
                (res) => {
                    console.log(res);
                    setIsAuthenticated(false);
                    setLoading(false);
                }
            );
    };

    if (loading) {
        return (
            <LinearProgress />
        );
    }

    return (
        <>
            {!isAuthenticated && (
                loginWithRedirect({})
            )}

            {(!isDeletingAccount && isAuthenticated) && (
                <AppContext.Provider value={{ store, api, session: { theme, setTheme, layout, setLayout } }}>
                    <ThemeProvider theme={theme === 'dark' ? themeDark : themeLight}> {/* Global MUI theme provider*/}
                        {/* <CssBaseline /> */}
                        <VApp
                            LogoutCallback={() => logout()}
                            userObject={user}
                        />
                    </ThemeProvider>
                    <IdleMonitor
                        logout={logout}
                        tokenMaxAge={user.sessionDuration}
                    />
                    <LocalRunner />
                </AppContext.Provider>
            )}
        </>
    );
}

export default AuthWrapper;