import React from 'react';

// mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import Paper from '@mui/material/Paper';

import appInfo from '../../../../package.json';


const AboutModal = ({
    handleClose = () => { },
    show = false
}) => {

    return (
        <Dialog
            open={show}
            onClose={handleClose}
        // backdrop="static"
        // keyboard={false}
        // contentClassName={css(styles.modalContainer)}
        >
            <DialogTitle>Vavanya™</DialogTitle>
            <DialogContent dividers>
                {/* <Typography gutterBottom>
                    Vavanya™
                </Typography> */}
                <Typography gutterBottom>
                    Current version: {appInfo.version}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>OK</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AboutModal;