import { createTheme } from "@mui/material/styles";

export const themeDark = createTheme({
    components: {
            MuiTabs: {
                styleOverrides: {
                    root: {
                        color: 'white',
                    },
                },
            },
        //     MuiToggleButton: {
        //         styleOverrides: {
        //             root: {
        //                 color: 'white',
        //                 borderColor: 'white',
        //                 borderStyle: 'solid'
        //             }
        //         }
        //     },
        //     MuiToolbar: {
        //         styleOverrides: {
        //             root: {
        //                 background: '#282c34',
        //             }
        //         }
        //     },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'monospace',
                    fontSize: 'large'
                }
            }
        },
        //     MuiButtonBase: {
        //         styleOverrides: {
        //             root: {
        //                 fontFamily: "monospace",
        //                 fontSize: "larger",
        //             }
        //         }
        //     }
    },
    palette: {
        mode: 'dark',
        // primary: {
        //     main: '#3f51b5',
        // },
        // secondary: {
        //     main: '#f50057',
        // },
        text: {
            // primary: '#006FCA',
            secondary: 'white',
        },
    },
    typography: {
        fontFamily: 'monospace',
        // fontFamily: 'Raleway',
        fontSize: 16
    }
}
);

export const themeLight = createTheme({
    components: {
            MuiTabs: {
                styleOverrides: {
                    root: {
                        color: 'white',
                    },
                },
            },
        //     MuiToggleButton: {
        //         styleOverrides: {
        //             root: {
        //                 color: 'white',
        //                 borderColor: 'white',
        //                 borderStyle: 'solid'
        //             }
        //         }
        //     },
        //     MuiToolbar: {
        //         styleOverrides: {
        //             root: {
        //                 background: '#282c34',
        //             }
        //         }
        //     },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: 'monospace',
                    fontSize: 'large'
                }
            }
        },
        //     MuiButtonBase: {
        //         styleOverrides: {
        //             root: {
        //                 fontFamily: "monospace",
        //                 fontSize: "larger",
        //             }
        //         }
        //     }
    },
    palette: {
        mode: 'light',
        // primary: {
        //     main: '#3f51b5',
        // },
        // secondary: {
        //     main: '#f50057',
        // },
        // text: {
        //     // primary: '#006FCA',
        //     secondary: 'white',
        // },
    },
    typography: {
        fontFamily: 'monospace',
        // fontFamily: 'Raleway',
        fontSize: 16
    }
}
);
