/**
 * @license
 * 
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview XML wrappers for block, category, value, field and shadow.
 * @author samelh@google.com (Sam El-Husseini)
 */

import React from 'react';
import { BlocklyComponent } from './BlocklyComponent';

export default BlocklyComponent;

export const Block = (p) => {
    const { children, ...props } = p;
    props.is = "blockly";
    return React.createElement("block", props, children);
};

export const Category = (p) => {
    const { children, ...props } = p;
    props.is = "blockly";
    // props.expanded = "true";
    return React.createElement("category", props, children);
};

export const Value = (p) => {
    const { children, ...props } = p;
    props.is = "blockly";
    return React.createElement("value", props, children);
};

export const Field = (p) => {
    const { children, ...props } = p;
    props.is = "blockly";
    return React.createElement("field", props, children);
};

export const Statement = (p) => {
    const { children, ...props } = p;
    props.is = "blockly";
    return React.createElement("statement", props, children);
};

export const Shadow = (p) => {
    const { children, ...props } = p;
    props.is = "blockly";
    return React.createElement("shadow", props, children);
};

export const Next = (p) => {
    const { children, ...props } = p;
    props.is = "blockly";
    return React.createElement("next", props, children);
};

export const Sep = (p) => {
    const { children, ...props } = p;
    props.is = "blockly";
    return React.createElement("sep", props, children);
};

export const Label = (p) => {
    const { children, ...props } = p;
    props.is = "blockly";
    return React.createElement("label", props, children);
};

// export { Block, Category, Value, Field, Statement, Shadow, Next, Sep, Label }