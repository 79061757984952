import React from 'react';
import ReactDOM from 'react-dom';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { SnackbarProvider } from 'notistack';
import IconButton from '@mui/material/IconButton';
import Zoom from '@mui/material/Zoom';
import CloseIcon from '@mui/icons-material/Close';

import './index.css';
import AuthWrapper from './AuthWrapper';
import * as serviceWorker from './serviceWorker';


// disable console logging for production mode
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
    console.log('>>DEVELOPMENT<<');
} else {
    // production code
    console.log = () => { };
}

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
}

ReactDOM.render(
    <DndProvider backend={HTML5Backend}>
        <SnackbarProvider
            maxSnack={3}
            ref={notistackRef}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            TransitionComponent={Zoom}
            action={(key) => (
                <IconButton onClick={onClickDismiss(key)}>
                    <CloseIcon />
                </IconButton>
            )}
        >
            <AuthWrapper />
        </SnackbarProvider>
    </DndProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
