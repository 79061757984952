import React from 'react';

// mui
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import Paper from '@mui/material/Paper';

// bootstrap
// import Modal from "react-bootstrap/Modal";
// import Button from "react-bootstrap/Button";
// import "bootstrap/dist/css/bootstrap.css";

// CSS
// import { css } from "aphrodite";

// VMT styles
// import styles from 'components/Styles';

const DialogModal = ({
    title = 'Dialog',
    handleClose = () => { },
    onPrimary = () => { },
    onSecondary = () => handleClose(),
    primaryBtnLabel = 'OK',
    secondaryBtnLabel = 'Cancel',
    show = false, children }) => {

    const handlePrimaryBtnClick = () => {
        onPrimary();
    }

    const handleSecondaryBtnClick = () => {
        onSecondary();
    }

    return (
        <Dialog
            open={show}
            onClose={handleClose}
        // backdrop="static"
        // keyboard={false}
        // contentClassName={css(styles.modalContainer)}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button variant='outlined' onClick={handleSecondaryBtnClick}>{secondaryBtnLabel}</Button>
                <Button variant='contained' autoFocus onClick={handlePrimaryBtnClick}>{primaryBtnLabel}</Button>
            </DialogActions>
        </Dialog>
    );
};



// const DialogModal = ({
//     title = 'Dialog',
//     handleClose = () => { },
//     onPrimary = () => { },
//     onSecondary = () => handleClose(),
//     primaryBtnLabel = 'OK',
//     secondaryBtnLabel = 'Cancel',
//     show = false, children }) => {

//     const handlePrimaryBtnClick = () => {
//         onPrimary();
//     }

//     const handleSecondaryBtnClick = () => {
//         onSecondary();
//     }

//     return (
//         <Modal
//             show={show}
//             onHide={handleClose}
//             // backdrop="static"
//             keyboard={false}
//             // contentClassName={css(styles.modalContainer)}
//         >
//             <Modal.Header closeButton>
//                 <Modal.Title>{title}</Modal.Title>
//             </Modal.Header>
//             <Modal.Body>
//                 {children}
//             </Modal.Body>
//             <Modal.Footer>
//                 <Button variant="secondary" onClick={handleSecondaryBtnClick}>{secondaryBtnLabel}</Button>
//                 <Button variant="primary" onClick={handlePrimaryBtnClick}>{primaryBtnLabel}</Button>
//             </Modal.Footer>
//         </Modal>
//     );
// };

export default DialogModal;