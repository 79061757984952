import AppStore from 'stores/app';
import IBrick from 'types/brick';
import IControl from 'types/control';

export default class Brick implements IBrick {
    id: string;
    name: string;
    type: string;
    anchor: string;
    controls: IControl[];

    constructor(private store: AppStore, brick: IBrick) {
        this.id = brick.id;
        this.name = brick.name;
        this.type = brick.type;
        this.anchor = brick.anchor;
        this.controls = brick.controls;
    }
}