// React
import { useState, useEffect } from 'react';

import CodePrettifier from 'components/CodePrettifier';
import useBlocklyEvents from 'utils/hooks/useBlocklyEvents';

// VMT common
import { Components } from 'utils/const'

import { dataBusStore, useStore } from 'stores';

export const JSCodePrettifier = () => {
    const { jsCode } = useBlocklyEvents();
    const [logContentToScroll, setLogContentToScroll] = useState('');
    const [dataBus] = useStore(dataBusStore);

    useEffect(() => {
        if (dataBus?.to && dataBus.from === Components.BASEMENT.key) {
            setLogContentToScroll(dataBus.content.blockId);
        }
    }, [dataBus]);

    return (
        <CodePrettifier code={jsCode} contentToScroll={logContentToScroll} />
    );
}