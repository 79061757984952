import AppStore from 'stores/app';
import ITreeNode from 'types/treeNode';
import ITreeData from 'types/treeData';

export default class TreeNode implements ITreeNode {
    id: string;
    text: string;
    type: string;
    parent_id: string;
    children: string[];
    data:ITreeData | null;

    constructor(private store: AppStore, node: ITreeNode) {
        this.id = node.id;
        this.text = node.text;
        this.type = node.type;
        this.parent_id = node.parent_id;
        this.children = node.children;
        this.data = node.data || null;
    }
}