// React
import { useState, useEffect } from 'react';

// Blockly
import Blockly from 'blockly/core';

// VMT Blockly
import BlocklyJS from 'blockly/javascript';
import 'blockly_vmt/generator/generator';

import { blocklyEventStore, useStore } from 'stores';

const useBlocklyEvents = () => {
    const [jsCode, setJSCode] = useState(null);
    const [wsXML, setWsXML] = useState(null);
    // const [logContentToScroll, setLogContentToScroll] = useState('');
    const [blocklyEvent] = useStore(blocklyEventStore, null);

    useEffect(() => {
        // console.log(blocklyEvent);
        if (blocklyEvent === null ||
            blocklyEvent.blocklyEvent === null ||
            blocklyEvent.blocklyWorkspace === null) return;
        const workspace = blocklyEvent.blocklyWorkspace;
        // console.log('VApp event', blocklyEvent.blocklyEvent);
        if ((blocklyEvent.blocklyEvent.type === Blockly.Events.BLOCK_DRAG && blocklyEvent.blocklyEvent.isStart === false) ||
            blocklyEvent.blocklyEvent.type === Blockly.Events.FINISHED_LOADING ||
            blocklyEvent.blocklyEvent.type === Blockly.Events.CREATE ||
            blocklyEvent.blocklyEvent.type === Blockly.Events.CHANGE ||
            blocklyEvent.blocklyEvent.type === Blockly.Events.DELETE ||
            blocklyEvent.blocklyEvent.type === Blockly.Events.VAR_RENAME) {
            // console.log('VApp UPDATE code', blocklyEvent.blocklyEvent);
            setJSCode(BlocklyJS.workspaceToCode(workspace));
            const xmlDom = Blockly.Xml.workspaceToDom(workspace);
            setWsXML(Blockly.Xml.domToPrettyText(xmlDom));
            return;
        }
    }, [blocklyEvent]);

    return {
        jsCode,
        setJSCode,
        wsXML,
        setWsXML,
    };
}

export default useBlocklyEvents;