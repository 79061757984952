import { getGenericStore } from './genericStore';

const addInMenuStore = Object.create(getGenericStore([]));
addInMenuStore.menus = addInMenuStore.state;
addInMenuStore.setState = function (menu) {
    const idx = this.menus.findIndex(({ title }) => title === menu.title);
    if (idx === -1) {
        this.menus = [...this.menus, menu];
    }
    else {
        this.menus[idx] = menu;
    }
    this.subject.next([...this.menus]);
}

export default addInMenuStore;