// import axios, { AxiosInstance } from "axios";
import axios from "axios";
import AppStore from "stores/app";
import BrickApi from "apis/brick";
import TreeApi from "apis/tree";
import ConstructionApi from "apis/construction";

import { apiEndpointStore } from 'stores';

const API_BASE_URL = apiEndpointStore.endpoint;

export default class AppApi {
    client = axios.create({
        baseURL: API_BASE_URL,
        withCredentials: true,
        headers: {
            post: {
                'Content-Type': 'application/json;charset=utf-8'
            }
        },
        validateStatus: (status) => {
            return status >= 200 && status < 499; // default
        }
    });

    brick: BrickApi;
    brickTree: TreeApi;
    constructionTree: TreeApi;
    construction: ConstructionApi;

    constructor(store: AppStore) {
        this.brick = new BrickApi(this, store);
        this.construction = new ConstructionApi(this, store);
        this.brickTree = new TreeApi(this, store, store.brickTree, '/brick_dir');
        this.constructionTree = new TreeApi(this, store, store.constructionTree, '/construction_dir');
    }
}