import React, { useState, useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import TreeItem from './TreeItem';
import { TreeActions, Types } from '../../utils/const'

const Global = createGlobalStyle`
* {
  box-sizing: border-box;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #191b21;
  overflow: hidden;
  font-family: 'Monospaced Number', 'Chinese Quote', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 21px;
}
html,
body,
div,
a,
i,
button,
select,
option,
optgroup,
hr,
br {
  user-select: none;
  cursor: default;
}
`;

const TreeComponent = React.memo(({
    tree: dataTree,
    onItemClick = () => { },
    onTreeChange = () => { },
    onContextMenu = () => { },
    onEditMode = () => { },
    nodesOnly = false,
    editNode = null,
    selectedItem = null,
    noDnD = false }) => {
    console.log('<TreeComponent>');
    const [currentNode, setCurrentNode] = useState(null);
    const [uiTreeArray, setUiTreeArray] = useState([]/*tree*/);
    const [treeContent, setTreeContent] = useState(<></>);

    const itemsRef = React.useRef([]);

    const onClick = React.useCallback((itemData) => {
        console.log('onClick', itemData);
        setCurrentNode(itemData);

        itemData?.ancestors.forEach(ancestorId => {
            const itemIndex = uiTreeArray.findIndex(item => item.id === ancestorId);
            uiTreeArray[itemIndex].expanded = true; // always expand
        });
        setUiTreeArray([...uiTreeArray]);

        const nodeElement = itemsRef.current.find(el => el.id === itemData.id);
        setTimeout(() => { //Start the timer
            nodeElement && nodeElement.scrollIntoView(
                { behavior: "smooth", block: 'nearest', inline: 'start' }
            );
        }, 500);

        onItemClick && onItemClick(itemData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [/*onItemClick,*/ uiTreeArray]);

    const onChange = React.useCallback((item, action, dropResult = null) => {
        console.log('onChange', item, action, dropResult);
        let index = -1;
        switch (action) {
            case TreeActions.Move:
                const droppedItem = item;
                if (dropResult.itemData.id !== droppedItem.itemData.id) {
                    if ((index = uiTreeArray.findIndex(item => item.id === droppedItem.itemData.id)) !== -1 &&
                        uiTreeArray[index].parent_id !== dropResult.itemData.id) {
                        uiTreeArray[index].parent_id = dropResult.itemData.id;
                        setUiTreeArray([...uiTreeArray]);
                        // call prop onTreeChange callback if required
                        // with updated item as a parameter
                        onTreeChange && onTreeChange(uiTreeArray[index], TreeActions.Move);
                    }
                }
                break;
            case TreeActions.Rename:
                console.log('onChange >> TreeActions.Rename', item);
                const renamedItem = item;
                index = uiTreeArray.findIndex(item => item.id === renamedItem.id);
                uiTreeArray[index].text = renamedItem.text;
                setUiTreeArray([...uiTreeArray]);
                // call prop onTreeChange callback if required
                // with updated item as a parameter
                onTreeChange && onTreeChange(uiTreeArray[index], TreeActions.Rename);
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [/*onTreeChange, */uiTreeArray]);

    const onExpandCollapse = React.useCallback(({ itemData, expanded }) => {
        console.log('onExpandCollapse', { itemData, expanded });
        const itemIndex = uiTreeArray.findIndex(item => item.id === itemData.id);
        uiTreeArray[itemIndex].expanded = expanded;
        setUiTreeArray([...uiTreeArray]);
    }, [uiTreeArray]);

    const buildTree = React.useCallback((rootItem, ancestors = []) => {
        // console.log('buildTree', rootItem, ancestors);
        if (!rootItem) return;
        // const subItems = treeArray.filter(item => item.parent_id === rootItem.id);
        let subItems = []
        if (nodesOnly) {
            subItems = rootItem.children?.map(id => uiTreeArray.find(item => item.id === id && item.type === Types.NODE));
        }
        else {
            subItems = rootItem.children?.map(id => uiTreeArray.find(item => item.id === id));
        }
        const key = rootItem.id;
        const name = rootItem.text;
        const isSelected = currentNode && rootItem.id === currentNode.id;
        const isInEditState = editNode?.id === rootItem.id ? true : false;
        const style = rootItem.type === Types.ITEM ? { color: '#37ceff' } : null;
        return (
            <TreeItem
                key={key}
                ref={item => itemsRef.current.push(item)}
                name={name}
                ancestors={ancestors}
                style={style}
                itemData={{ ...rootItem, ancestors: ancestors }}
                isSelected={isSelected}
                defaultOpen={true}
                isOpen={rootItem.expanded}
                isInEditMode={isInEditState}
                onClick={onClick}
                onEditMode={onEditMode}
                onChange={onChange}
                onContextMenu={onContextMenu}
                onExpandCollapse={onExpandCollapse}
                noDnD={noDnD}
            >
                {subItems?.map(item => buildTree(item, [...ancestors, key]))}
            </TreeItem>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentNode, onChange, onClick, /*onContextMenu, onEditMode,*/ onExpandCollapse, uiTreeArray, editNode]);

    useEffect(() => {
        // console.log('useEffect [tree]', tree);
        const buildTree = (rootItem, ancestors = [], prevTree) => {
            const children = dataTree.filter(item => item.parent_id === rootItem.id);
            // check for the previous state of the node
            const prevRootItem = prevTree.find(item => item.id === rootItem.id);
            const itemExpandedState = prevRootItem ?
                prevRootItem.expanded :
                (rootItem.parent_id ? false : true); // collapsed by default
            return [{ ...rootItem, expanded: itemExpandedState, ancestors }, children.map(child => buildTree(child, [...ancestors, rootItem.id], prevTree))];
        };
        console.log('dataTree', dataTree);
        const rootItem = dataTree.find(item => item.parent_id === undefined);
        if (rootItem) {
            console.time();
            setUiTreeArray((prevTree) => {
                console.log('UITree', prevTree);
                return buildTree(rootItem, [], prevTree).flat(Infinity)
            });
            console.timeEnd();
        }
        else {
            setUiTreeArray(dataTree);
        }
    }, [dataTree]);

    useEffect(() => {
        // console.log('Tree update', tree);
        console.log('useEffect [treeArray, currentNode]'/*, treeArray, currentNode*/);
        const rootItem = uiTreeArray.find(item => item?.parent_id === undefined);
        if (rootItem) {
            itemsRef.current = [];
            // console.time('buildTree');
            setTreeContent(buildTree(rootItem));
            // console.timeEnd('buildTree');
            rootItem.id !== '0xFF' && !currentNode && onClick(rootItem); //setCurrentNode(rootItem);
        }
    }, [uiTreeArray, currentNode, buildTree, onClick]);

    useEffect(() => {
        console.log('useEffect [selectedItem]', selectedItem);
        selectedItem && onClick(uiTreeArray.find(item => item.id === selectedItem.id));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedItem]);

    return (
        <>
            <Global />
            {treeContent}
        </>
    );
});

export default TreeComponent;

/*
TREE ITEMS EXAMPLES:
--------------------
        <Tree name="main" defaultOpen on={test}>
        <Tree name="hello" on={test}/>
        <Tree name="subtree with children" on={test}>
            <Tree name="hello" on={test} />
            <Tree name="sub-subtree with children" on={test}>
            <Tree name="child 1" style={{ color: '#37ceff' }} on={test} />
            <Tree name="child 2" style={{ color: '#37ceff' }} on={test}/>
            <Tree name="child 3" style={{ color: '#37ceff' }} on={test} />
            <Tree name="custom content" on={test}>
                <div style={{ position: 'relative', width: '100%', height: 200, padding: 10 }}>
                <div style={{ width: '100%', height: '100%', background: 'black', borderRadius: 200 }} />
                </div>
            </Tree>
            </Tree>
            <Tree name="hello" on={test} />
        </Tree>
        <Tree name="world" on={test} />
        <Tree name={<span>something something</span>} on={test} />
        </Tree>

*/