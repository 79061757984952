import { useCallback, useState, useMemo } from 'react';

import * as Blockly from 'blockly/core';
import { createBlockData } from './xml';

export const useBFBlocklyController = () => {
    const [blocklyEvent, setBlocklyEvent] = useState(null);
    const [selectedBlockData, setSelectedBlockData] = useState(null);
    const [labelPos, setLabelPos] = useState({ x: -100, y: -100 });


    const getHandleResize = useCallback((blocklyWorkspace) => {
        const handleResize = (event) => {
            // console.log('handleResize', event);
            // if (bfRef === null || brickFactoryWorkspace === null) return;
            const rect = blocklyWorkspace?.getInjectionDiv().getBoundingClientRect();
            //const rect = bfRef.getBoundingClientRect();
            const blocklySvg = blocklyWorkspace?.getParentSvg();
            blocklySvg?.setAttribute('width', `${rect.width}px`);
            blocklySvg?.setAttribute('height', `${rect.height}px`);
            setLabelPos({ x: rect?.x + rect?.width - 20, y: rect?.y + 10 });
        }
        return handleResize;
    }, []);

    const changeListener = useCallback((event) => {
        // console.log('BF >> Event >>', event);
        // to avoid 'bombarding' with Blockly events we skip VIEWPORT_CHANGE
        if (event.type === Blockly.Events.VIEWPORT_CHANGE) return;
        setBlocklyEvent(event);
        console.log('BF >> Event >>', event);

        // delete all newly ceated blocks in case no construction is loaded
        if (event.type === Blockly.Events.CREATE) {
            console.log('workspaceChange', event);
            const workspace = Blockly.Workspace.getById(event.workspaceId);
            const blocks = workspace.getTopBlocks(false);
            const baseTestBlock = blocks.find(block => block.type === 'factory_base');
            const block = workspace.getBlockById(event.blockId);
            // console.log('New Block 1', block);
            if (baseTestBlock === undefined) {
                // delete the control if there is no brick to edit
                block.dispose();
            }
            else {
                // delete the control's data if it was copied and than pasted
                // delete block.data;
                console.log('block.data', block.data);
                // add initial data
                let newBlockData = {};
                if (block.data) {
                    newBlockData = JSON.parse(block.data)
                }
                else {
                    newBlockData = createBlockData({
                        name: 'control_name',
                        type: 'generic',
                        anchor: '<selector>'
                    });
                }

                delete newBlockData.id;
                block['data'] = JSON.stringify(newBlockData);
                // updateBrickInfo();
            }
            // console.log('New Block 2', block);
        }

        // if (event.type === Blockly.Events.DELETE) {
        //     console.log('workspaceChange', event);
        //     updateBrickInfo();
        // }

        if (event.type === Blockly.Events.CHANGE) {
            if (event.element !== 'field') return;
            console.log('workspaceChange11', event);
            const workspace = Blockly.Workspace.getById(event.workspaceId);
            const block = workspace.getBlockById(event.blockId);
            console.log('Changed block', block);
            if (block && block.data) {
                if (event.name === 'NAME' || event.name === 'INPUT_CONTROL_NAME') {
                    const blockDataJson = JSON.parse(block.data);
                    block['data'] = JSON.stringify(createBlockData({
                        id: blockDataJson.id,
                        name: event.newValue,
                        anchor: blockDataJson.ID,
                        type: blockDataJson.type,
                    }));
                }
                // else if (event.name === 'INPUT_CONTROL_ID') {
                //     block['data'] = JSON.stringify(createBlockData({
                //         ...JSON.parse(block.data),
                //         ID: event.newValue,
                //     }));
                // }
                setSelectedBlockData({ blockId: block.id, ...JSON.parse(block.data) });
            }
        }

        if (event.type === Blockly.Events.SELECTED) {
            const workspace = Blockly.Workspace.getById(event.workspaceId);
            // console.log('UNDO stack', workspace.getUndoStack());
            const block = workspace.getBlockById(event.newElementId);
            console.log('Selected block', block);
            if (block && block.data) {
                // const blockData = JSON.parse(block.data);
                console.log('SELECTED block data', JSON.parse(block.data));
                setSelectedBlockData({ blockId: block.id, ...JSON.parse(block.data) });
            }
            else {
                setSelectedBlockData(null);
            }
            //     if (block && block.getPreviousBlock() && !block.isEnabled()) {
            //         console.log('Enable and create new');
            //         // enable the "next possible" control
            //         block.setEnabled(true);
            //         block['nextControl'] = false;
            //         block.setNextStatement(true, 'control');
            //         // add new "next possible" disabled control
            //         // and connect the control to prev. control
            //         const newControlBlock = workspace.newBlock('control_generic');
            //         newControlBlock['nextControl'] = true;
            //         newControlBlock.setNextStatement(true, 'NON_EXISTING_TYPE');
            //         block.nextConnection.connect(newControlBlock.previousConnection);
            //         newControlBlock.initSvg();
            //         newControlBlock.render();
            //     }
        }
    }, []);

    return useMemo(
        () => ({
            blocklyChangeListener: changeListener,
            blocklyEvent: blocklyEvent,
            selectedBlockData: selectedBlockData,
            setSelectedBlockData: setSelectedBlockData,
            getHandleResize: getHandleResize,
            labelPos: labelPos
        }),
        [blocklyEvent, changeListener, getHandleResize, labelPos, selectedBlockData]
    );
};