import { BehaviorSubject } from 'rxjs';

export const getGenericStore = (initialState = null) => {
    return /*GenericStore =*/ {
        subject: new BehaviorSubject(initialState),
        subscribe: function (setData) { this.subject.subscribe(setData) },
        state: initialState,
        setState: function (newState) {
            this.state = newState;
            this.subject.next(this.state);
        },
        initialState
    };
}
