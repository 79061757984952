import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Label = styled('div')`
    position: fixed;
    color: aqua;
    opacity: 0.8;
    background: darkslategray;
    box-shadow: 0px 2px 10px #999999;
    z-index: 1000;
    padding: 3px;
    font-family: monospace;
    font-size: larger;
`;

const FloatingLabel = ({ x, y, text }) => {
    const [labelRef, setLabelRef] = useState(null);

    useEffect(() => {
        if (labelRef) {
            labelRef.style.left = `${x - labelRef.clientWidth}px`;
            labelRef.style.top = `${y}px`;
        }
    }, [x, y, labelRef]);

    return (
        <Label ref={setLabelRef}>{text}</Label>
    );
};

export default FloatingLabel;
