// React
import React, { useState, useEffect } from 'react';

// bootstrap
import Table from "react-bootstrap/Table";
import Container from 'react-bootstrap/Container';
import "bootstrap/dist/css/bootstrap.css";

import { useSnackbar } from 'notistack';

// MobX
import { observer } from 'mobx-react-lite';

// CSS
import { css } from "aphrodite";

// VMT styles
import styles from '../Styles';
import { constructions } from 'utils/VApi';

// Blockly
import { useAppContext } from "app-context";

// VMT components
import TreeComponent from 'components/TreeComponent';
import DialogModal from 'components/Modals/DialogModal';
import { TreeActions, CRUD, ModeStates, Types, Components, ContextMenuItems } from 'utils/const';
import PopupMenu, { MenuItem, MenuSeparator } from 'components/PopupMenu';

import {
    selectedBrickIDsStore, /*selectedBrickTreeNodeStore,*/
    brickFactoryDataStore, brickId2EditStore, dataBusStore, useStore
} from '../../stores';

const initialState = {
    x: -1,
    y: -1,
    selectedItem: null
};

const ME = Components.BRICK_TREE.key;

export const BrickTreeComponent = observer((/*props*/) => {
    console.log('<BrickTreeComponent>');
    const { api, store } = useAppContext();

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedTreeItem, setSelectedTreeItem] = useState(null);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [nodeIdsToDelete, setNodeIdsToDelete] = useState([]);
    const [brickIdsToDelete, setBrickIdsToDelete] = useState([]);
    const [brickUsageTable, setBrickUsageTable] = useState(<></>);
    const [menuXY, setMenuXY] = useState(initialState);
    const [isCtxMenu, _setCtxMenu] = useState(false);
    const isCtxMenuRef = React.useRef(isCtxMenu);
    const setCtxMenu = data => {
        isCtxMenuRef.current = data;
        _setCtxMenu(data);
    };

    // stores
    const [dataToProcess, setDataToProcess] = useStore(brickFactoryDataStore);
    const [, setSelectedBrickIDs] = useStore(selectedBrickIDsStore);
    // const [selectedBrickTreeNode, setSelectedBrickTreeNode] = useStore(selectedBrickTreeNodeStore);
    const [selectedBrickTreeNode, setSelectedBrickTreeNode] = useState(null); //useStore(selectedBrickTreeNodeStore);
    const [, setBrickId2Edit] = useStore(brickId2EditStore);
    const [dataBus/*, setDataBusContent*/] = useStore(dataBusStore);

    const { enqueueSnackbar/*, closeSnackbar*/ } = useSnackbar();

    const openDeleteModal = () => setShowDeleteModal(true);
    const closeDeleteModal = () => setShowDeleteModal(false);

    // scroll to the brick in the the tree
    useEffect(() => {
        if (dataBus?.to === ME && dataBus?.content?.brickId !== null) {
            const brickId = dataBus.content.brickId;
            console.log('dataBus', dataBus, brickId);
            console.log('store.brickTree.nodes', store.brickTree.nodes);
            // find node for given brick id
            const node = store.brickTree.nodes.find(node => node.data?.reference_id === brickId);
            console.log('node', node);
            node && setSelectedTreeItem({ id: node.id });
            // "consume" the data from the bus
            // setDataBusContent({ to: ME, from: ME, content: { brickId: null } });
        }
    }, [dataBus, /*setDataBusContent,*/ store.brickTree.nodes]);

    const apiCreateBrickTreeNode = async (treeNodeJson) => {
        const node = await api.brickTree.create(treeNodeJson);
        setSelectedTreeItem({ id: node.id });
        setItemToEdit(node);
    };

    const apiConstructionsBricksUsage = React.useCallback((brickIds) => {
        console.log('constructions.bricksUsage');
        return constructions()
            .bricksUsage(brickIds)
            .then((json) => {
                // console.log(json);
                return json;
            },
                (error) => { console.error(error) });
    }, []);

    const apiBricksCRUD = async (action, brickId, treeNode, brickData = null) => {
        switch (action) {
            case CRUD.Create:
                console.log('bricks.create', action, brickId, treeNode, brickData);
                const newBrick = await api.brick.create(brickData);
                if (newBrick) {
                    // add brick to the brick tree directory
                    const newTreeNodeItem = {
                        parent_id: treeNode?.id || selectedBrickTreeNode.id,
                        type: Types.ITEM,
                        text: newBrick.name,
                        data: {
                            reference_id: newBrick.id,
                        }
                    };
                    console.log(newTreeNodeItem);
                    apiCreateBrickTreeNode(newTreeNodeItem);
                    enqueueSnackbar('Brick was successfully created', { variant: 'success', persist: false });
                }
                else {
                    enqueueSnackbar('Cannot create brick', { variant: 'error', persist: true });
                }
                break;
            case CRUD.Update:
                console.log('bricks.update', action, brickId, treeNode, brickData);
                const updatedBrick = await api.brick.update(brickId, brickData);
                if (updatedBrick) {
                    console.log('updatedBrick', updatedBrick);
                    enqueueSnackbar('Brick was successfully updated', { variant: 'success', persist: false });
                }
                else {
                    enqueueSnackbar('Cannot updated brick', { variant: 'error', persist: true });
                }
                break;
            default:
                break;
        }
    };

    const apiUpdateBrickTree = async (action, id, dataToUpdate) => {
        try {
            const prevNodeText = store.brickTree.nodesById.get(id).text;
            const node = await api.brickTree.update(id, dataToUpdate);
            console.log('api.brickTree.update', node);
            if (node.type === Types.ITEM && dataToUpdate?.text && dataToUpdate.text !== prevNodeText) {
                // update Brick record
                const brickId = node.data.reference_id;
                apiBricksCRUD(CRUD.Update, brickId, null, { brick_caption: dataToUpdate.text });
            }
        }
        catch (error) {
            console.error(error);
        }
    };

    const onItemClick = (itemData) => {
        console.log('Tree item: ', itemData);
        console.log('----------------');
        let brickIds = [];
        if (itemData.type === Types.ITEM) {
            brickIds = [itemData.data.reference_id];
        }
        else {
            itemData.children.forEach(childNodeId => {
                const childNode = store.brickTree.nodesById.get(childNodeId);
                if (childNode?.type === Types.ITEM) {
                    brickIds.push(childNode.data.reference_id);
                }
            });
        }
        console.log(brickIds);
        setSelectedBrickIDs([...brickIds]);
        setBrickId2Edit(brickIds.length > 0 ? brickIds[0] : null);
        setSelectedBrickTreeNode(
            itemData.type === Types.NODE ? itemData : store.brickTree.nodesById.get(itemData.parent_id)
        );
    };

    const getAllChildItems = (parentNode) => {
        let childItems = []
        parentNode.children.forEach(nodeId => {
            const node = store.brickTree.nodesById.get(nodeId);
            if (node) {
                childItems.push(node);
                if (node.children.length > 0) {
                    const subNodes = getAllChildItems(node);
                    childItems.push(...subNodes);
                }
            }
        });
        return childItems;
    };

    const onTreeChange = (itemData, action) => {
        console.log('onTreeChange: ', action, itemData);

        switch (action) {
            case TreeActions.Rename:
                apiUpdateBrickTree(TreeActions.Rename, itemData.id, { text: itemData.text });
                break;
            case TreeActions.Move:
                // change parent_id
                apiUpdateBrickTree(TreeActions.Move, itemData.id, { parent_id: itemData.parent_id });
                break;
            case TreeActions.Delete:
                // prepare list of item to delete
                let treeNodesToDelete = itemData?.parent_id ? [itemData] : [];
                if (itemData.type === Types.NODE) {
                    const childrenToDelete = getAllChildItems(itemData);
                    treeNodesToDelete = [...treeNodesToDelete, ...childrenToDelete];
                }
                if (treeNodesToDelete.length === 0) return;
                setNodeIdsToDelete(treeNodesToDelete.map(node => node.id))
                const bricksInfo = treeNodesToDelete
                    .filter(item => item.type === Types.ITEM)
                    .map(node => {
                        const brickDetail = {}
                        brickDetail['id'] = node.data.reference_id;
                        brickDetail['text'] = node.text;
                        return brickDetail;
                    });

                if (bricksInfo.length > 0) {
                    const brickIds2Delete = bricksInfo.map(brickInfo => brickInfo.id);
                    setBrickIdsToDelete(brickIds2Delete);

                    // fetch bricks usage information
                    apiConstructionsBricksUsage(brickIds2Delete)
                        .then(({ data }) => {
                            let brickUsageTblContent = []
                            let brickIdsInUse = [];
                            if (data.length > 0) {
                                brickIdsInUse = data.map(item => Object.keys(item)[0]);
                                brickUsageTblContent = data.map(item => { // data -> [{'brick_id': [{id: 'constr_id', text: '...'}, {...}, ...]}]
                                    const brickId = Object.keys(item)[0]; // brick id
                                    const constructionsInfo = item[brickId]; // array of constrs info {id, text}
                                    const tblRows = constructionsInfo.map((constDetail, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{bricksInfo.find(item => item.id === brickId).text}</td>
                                                <td>{constDetail.text}</td>
                                            </tr>
                                        );
                                    });
                                    return tblRows;
                                });
                            }
                            const restBrickTblContent = brickIds2Delete
                                .filter(brickId => !brickIdsInUse.includes(brickId))
                                .map(brickId => {
                                    return (
                                        <tr key={brickId}>
                                            <td>{bricksInfo.find(item => item.id === brickId).text}</td>
                                            <td>N/A</td>
                                        </tr>
                                    );
                                });

                            const tableJsx =
                                <>
                                    Selected bricks are used in following constructions
                                    <Container className={css(styles.modalScrollingPane)}>
                                        {/* variant="dark" */}
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                    <th>Brick Name</th>
                                                    <th>Construction Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {brickUsageTblContent}
                                                {restBrickTblContent}
                                            </tbody>
                                        </Table>
                                    </Container>
                                </>;
                            setBrickUsageTable(tableJsx);
                            // show modal warning
                            openDeleteModal();
                        });
                }
                else {
                    // show modal warning
                    openDeleteModal();
                }
                break;
            default:
                break;
        }
    };

    const modalHandleCancel = () => {
        setNodeIdsToDelete([]);
        setBrickIdsToDelete([]);
        setBrickUsageTable(<></>);
        closeDeleteModal();
    };

    const modalHandleDelete = async () => {
        console.log('modalHandleDelete');
        //DELETE directory nodes
        try {
            const childNode = store.brickTree.nodesById.get(nodeIdsToDelete[0]);
            const deletedNodes = await api.brickTree.delete(nodeIdsToDelete);
            console.log('brickDir.delete', deletedNodes);
            // nothing in flyout
            setSelectedBrickIDs([]);
            // select the parent node
            const parentNode = store.brickTree.nodesById.get(childNode.parent_id);
            setSelectedTreeItem(parentNode);
            // delete brick's data from the BricksDataStore
            brickIdsToDelete.forEach(brickId => {
                store.brick.delete(brickId);
            });
            // clear the "data to delete"
            modalHandleCancel();
        }
        catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (dataToProcess === null) return;
        console.log(dataToProcess);
        switch (dataToProcess.mode.state) {
            case ModeStates.NEW_STATE:
                apiBricksCRUD(CRUD.Create, null, dataToProcess.nodeId, dataToProcess.dataToStore);
                setDataToProcess(null);
                break;
            case ModeStates.EDIT_STATE:
                apiBricksCRUD(CRUD.Update, dataToProcess.brickId, null, dataToProcess.dataToStore);
                setDataToProcess(null);
                break;
            default:
                console.error(`Unknown brick mode state: ${dataToProcess.mode.state}`);
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataToProcess, /*apiBricksCRUD,*/ setDataToProcess]);

    const onContextMenu = (posX, posY, item) => {
        setMenuXY({ x: posX, y: posY, selectedItem: item });
        setCtxMenu(true);
    };

    const onEditMode = ({ itemData, isEditMode }) => {
        console.log('onEditMode', { itemData, isEditMode });
        isEditMode ? setItemToEdit(itemData) : setItemToEdit(null);
    };

    const onMenuItemSelect = (menuItemText) => {
        console.log('onMenuItemSelect', menuItemText);
        console.log('onMenuItemSelect', menuXY.selectedItem);
        const treeNode = menuXY.selectedItem;
        switch (menuItemText) {
            case ContextMenuItems.NewNode:
                apiCreateBrickTreeNode({
                    parent_id: treeNode.id,
                    type: Types.NODE,
                    text: 'New Node #1'
                    // no data.reference_id
                });
                break;
            case ContextMenuItems.RenameNode:
                setItemToEdit(treeNode);
                break;
            case ContextMenuItems.DeleteNode:
                onTreeChange(treeNode, TreeActions.Delete);
                break;
            case ContextMenuItems.NewBrick:
                // create new brick payload
                const brickData = {};
                brickData.brick_type = 'brick';
                brickData.brick_caption = 'brick_name';
                brickData.controls = [];
                apiBricksCRUD(CRUD.Create, null, treeNode, brickData);
                break;
            default:
                break;
        }
    };

    const isNodeSelected = () => menuXY?.selectedItem?.type === Types.NODE;

    return (
        <>
            <TreeComponent
                tree={store.brickTree.nodes}
                onItemClick={onItemClick}
                onTreeChange={onTreeChange}
                onContextMenu={onContextMenu}
                onEditMode={onEditMode}
                editNode={itemToEdit}
                selectedItem={selectedTreeItem}
            />
            <PopupMenu
                posX={menuXY.x}
                posY={menuXY.y}
                onSelect={onMenuItemSelect}
            >
                {isNodeSelected() ? <MenuItem text={ContextMenuItems.NewNode} /> : <></>}
                {isNodeSelected() ? <MenuItem text={ContextMenuItems.NewBrick} /> : <></>}
                <MenuItem text={ContextMenuItems.RenameNode} />
                <MenuItem text={ContextMenuItems.DeleteNode} />
                <MenuSeparator />
                <MenuItem text={ContextMenuItems.NodeDetails} disabled />
            </PopupMenu>
            {/* Delete bricks/nodes modal*/}
            <DialogModal
                title='Deleting...'
                show={showDeleteModal}
                handleClose={closeDeleteModal}
                primaryBtnLabel='Delete'
                onPrimary={modalHandleDelete}
                onSecondary={modalHandleCancel}
            >
                {brickUsageTable}
                Are you sure?
            </DialogModal>
        </>
    );
});