import AppStore from "stores/app";
import IConstuction from "types/construction";

export default class Constuction implements IConstuction {

    id: string;
    workspace_name: string;
    workspace_id: string;
    bricks: string[];
    controls: string[];

    constructor(private store: AppStore, construction: IConstuction) {
        this.id = construction.id;
        this.workspace_name = construction.workspace_name;
        this.workspace_id = construction.workspace_id;
        this.bricks = construction.bricks;
        this.controls = construction.controls;
    }
}