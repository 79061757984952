import AppStore from "stores/app";
import AppApi from "apis/app";
import IConstruction from "types/construction";

/*

export const constructions = () => {
    const url = '/constructions';
    return {
        [+] getAll: () => axios.get(url),
        [+] create: (toCreate) => axios.post(url, toCreate),
        [+] update: ({ id, toUpdate }) => axios.patch(`${url}/${id}`, toUpdate),
        [-] getOne: ({ id }) => axios.get(`${url}/${id}`),
        [-] delete: ({ id }) => axios.delete(`${url}/${id}`),
        [-] bricksUsage: (brickIds) => axios.get(`${url}/bricks_usage`, { params: { brickIds } }),
    };
};

*/
export default class ConstructionApi {

    private readonly url = '/constructions';

    constructor(private api: AppApi, private store: AppStore) { }

    async getAll() {
        const res = await this.api.client.get(this.url);
        this.store.construction.load(res.data.map((constructionJson: any) => constructionJson as IConstruction));
    }

    async getOne(id: string) {
        const res = await this.api.client.get(`${this.url}/${id}`);
        return res.data.workspace_xml;
    }

    async create(constructionJson: any): Promise<IConstruction | null> {
        let construction: IConstruction | null = null;
        try {
            const res = await this.api.client.post(this.url, constructionJson);
            construction = res.data as IConstruction;
            this.store.construction.add(construction);
        }
        catch { }
        console.log('construction', construction);
        return construction;
    }

    async update(id: string, constructionJson: any): Promise<IConstruction | null> {
        let construction: IConstruction | null = null;
        try {
            const res = await this.api.client.patch(`${this.url}/${id}`, constructionJson);
            construction = res.data as IConstruction;
            this.store.construction.update(construction);
        }
        catch { }
        console.log('construction', construction);
        return construction;
    }

    // async delete(nodeIds: string[]) {
    //     let deletedNodes: ITreeNode[] | [] = [];
    //     try {
    //         const res = await this.api.client.delete(`${this.url}/nodes`, { params: { nodeIds } });
    //         deletedNodes = res.data.nodes as ITreeNode[];
    //         // const updatedNodes = res.data.parents as ITreeNode[];
    //         this.treeStore.delete(nodeIds);
    //     }
    //     catch { }
    //     console.log('deletedNodes', deletedNodes);
    //     return deletedNodes;
    // }
}