import * as Blockly from 'blockly/core';
import { Colors } from '../../utils/const';

// construction block color
const BASE_TEST_COLOR = Colors.BASE_TEST_COLOR; //'#2E476A';
// WebdriveIO block color
const WDIO_CONFIGURATION_COLOR = Colors.WDIO_CONFIGURATION_COLOR; //'#3E576A';

// Brick and bricks' controls blocks colors
const BRICK_COLOR = Colors.BRICK_COLOR; //'#444C5C'; //'#444C5C';
const CONTROL_COLOR = Colors.CONTROL_COLOR; //'#4E576A'; //'#4E576A';
const GROUP_COLOR = Colors.GROUP_COLOR; //'#4E576A'; //'#4E576A';

// actions blocks colors
const ACTION_INPUT_COLOR = Colors.ACTION_INPUT_COLOR; //'#CE5A57';
const ACTION_GETTER_COLOR = Colors.ACTION_GETTER_COLOR; //'#E1B16A';
const ACTION_VERIFY_COLOR = Colors.ACTION_VERIFY_COLOR; //'#78A5A3';
// browser actions
const ACTION_BROWSER_COLOR = Colors.ACTION_BROWSER_COLOR; //'#138D90';

Blockly.defineBlocksWithJsonArray([
    {
        "type": "base_test",
        "message0": "%1 %2 %3",
        "args0": [
            {
                "type": "field_input",
                "name": "TEST_NAME",
                "text": "Test Case"
            },
            {
                "type": "input_dummy"
            },
            {
                "type": "input_statement",
                "name": "TEST_CONTENT",
                "check": "Technology"
            }
        ],
        "colour": BASE_TEST_COLOR,
        "tooltip": "",
        "helpUrl": ""
    },
    {
        "type": "webdriverio_sync",
        "message0": "Configuration %1 %2 Actions %3 %4",
        "args0": [
            {
                "type": "input_dummy"
            },
            {
                "type": "input_statement",
                "name": "CONFIG",
                "check": "Config"
            },
            {
                "type": "input_dummy"
            },
            {
                "type": "input_statement",
                "name": "TEST_CONTENT",
                "check": ["Brick", "null"]
            }
        ],
        "previousStatement": "Technology",
        "colour": WDIO_CONFIGURATION_COLOR,
        "tooltip": "",
        "helpUrl": ""
    },
    {
        "type": "action_type",
        "message0": "%1",
        "args0": [
            {
                "type": "field_dropdown",
                "name": "ACTION",
                "options": [
                    [
                        "Click",
                        "CLICK"
                    ],
                    [
                        "DoubleClick",
                        "DBL_CLICK"
                    ],
                    [
                        "Hover",
                        "HOVER"
                    ]
                ]
            }
        ],
        "inputsInline": true,
        "nextStatement": "Action",
        "output": "Action",
        "colour": ACTION_INPUT_COLOR,
        "tooltip": "",
        "helpUrl": ""
    },
    {
        "type": "action_stack",
        "message0": "%1",
        "args0": [
            {
                "type": "field_dropdown",
                "name": "ACTION",
                "options": [
                    [
                        "Click",
                        "CLICK"
                    ],
                    [
                        "DoubleClick",
                        "DBL_CLICK"
                    ],
                    [
                        "Hover",
                        "HOVER"
                    ]
                ]
            }
        ],
        "inputsInline": true,
        "previousStatement": "Action",
        "nextStatement": "Action",
        "colour": ACTION_INPUT_COLOR,
        "tooltip": "",
        "helpUrl": ""
    },
    {
        "type": "action_value_to_statement",
        "message0": "%1",
        "args0": [
            {
                "type": "input_statement",
                "name": "STATEMENT"
            }
        ],
        "output": ["Boolean", "String", "Number"],
        "style": "math_blocks"
    },
    {
        "type": "vmt_variables_get",
        "message0": "%1",
        "args0": [
            {
                "type": "field_variable",
                "name": "VAR",
                "variable": "%{BKY_VARIABLES_DEFAULT_NAME}"
            }
        ],
        "output": null,
        "style": "variable_blocks",
        "helpUrl": "%{BKY_VARIABLES_GET_HELPURL}",
        "tooltip": "%{BKY_VARIABLES_GET_TOOLTIP}",
        "extensions": ["contextMenu_variableSetterGetter"]
    },
    {
        "type": "vmt_variables_set",
        "message0": "%{BKY_VARIABLES_SET}",
        "args0": [
            {
                "type": "field_variable",
                "name": "VAR",
                "variable": "%{BKY_VARIABLES_DEFAULT_NAME}"
            },
            {
                "type": "input_value",
                "name": "VALUE",
                "check": ["Boolean", "String", "Number"]
            }
        ],
        // "previousStatement": null,
        // "nextStatement": null,
        "previousStatement": ["Brick", "null"],
        "nextStatement": ["Brick", "null"],
        "style": "variable_blocks",
        "tooltip": "%{BKY_VARIABLES_SET_TOOLTIP}",
        "helpUrl": "%{BKY_VARIABLES_SET_HELPURL}",
        "extensions": ["contextMenu_variableSetterGetter"]
    },
    {
        "type": "text_concat",
        "message0": "%1 + %2",
        "args0": [
            {
                "type": "input_value",
                "name": "TEXT_A",
                "check": "String"
            },
            {
                "type": "input_value",
                "name": "TEXT_B",
                "check": "String"
            }
        ],
        "output": "String",
        "style": "text_blocks",
        "inputsInline": true,
        "helpUrl": "",
        "tooltip": ""
    },
]);

// const plusImage =
//     'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC' +
//     '9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBkPSJNMT' +
//     'ggMTBoLTR2LTRjMC0xLjEwNC0uODk2LTItMi0ycy0yIC44OTYtMiAybC4wNzEgNGgtNC4wNz' +
//     'FjLTEuMTA0IDAtMiAuODk2LTIgMnMuODk2IDIgMiAybDQuMDcxLS4wNzEtLjA3MSA0LjA3MW' +
//     'MwIDEuMTA0Ljg5NiAyIDIgMnMyLS44OTYgMi0ydi00LjA3MWw0IC4wNzFjMS4xMDQgMCAyLS' +
//     '44OTYgMi0ycy0uODk2LTItMi0yeiIgZmlsbD0id2hpdGUiIC8+PC9zdmc+Cg==';

const minusImage =
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAw' +
    'MC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij48cGF0aCBkPS' +
    'JNMTggMTFoLTEyYy0xLjEwNCAwLTIgLjg5Ni0yIDJzLjg5NiAyIDIgMmgxMmMxLjEwNCAw' +
    'IDItLjg5NiAyLTJzLS44OTYtMi0yLTJ6IiBmaWxsPSJ3aGl0ZSIgLz48L3N2Zz4K';

Blockly.Blocks['vmt_brick'] = {
    init: function () {
        this.jsonInit({
            // "type": "vmt_brick",
            "message0": "%1 %2 %3 %4",
            "args0": [
                {
                    "type": "field_image",
                    "name": "EXPAND_COLLAPSE",
                    "src": minusImage,
                    "width": 15,
                    "height": 15,
                    "alt": "+",
                    "tooltip": "Collapse block"
                },
                {
                    "type": "field_input",
                    "name": "brick_caption",
                    "text": "brick_caption"
                },
                {
                    "type": "input_dummy"
                },
                {
                    "type": "input_statement",
                    "name": "CONTROLS",
                    "check": "Control"
                }
            ],
            "previousStatement": ["Brick", "null"],
            "nextStatement": ["Brick", "null"],
            "colour": BRICK_COLOR,
            "tooltip": "",
            "helpUrl": ""
        });
        this.getField('EXPAND_COLLAPSE').setOnClickHandler(function () {
            this.getSourceBlock().setCollapsed(true);
        });
    }
};

const vmtControlJson = {
    // "type": "vmt_control",
    "message0": "%1 %2",
    "args0": [
        {
            "type": "field_label_serializable",
            "name": "CONTROL_NAME",
            "text": "Control"
        },
        {
            "type": "input_value",
            "name": "ACTION",
            "check": "Action"
        }
    ],
    "previousStatement": "Control",
    "nextStatement": "Control",
    "colour": CONTROL_COLOR,
    "tooltip": "",
    "helpUrl": ""
};

Blockly.Blocks['vmt_control'] =
    Blockly.Blocks['vmt_control_last'] = {
        init: function () {
            this.jsonInit(vmtControlJson);
            // this.getField('EXPAND_COLLAPSE').setOnClickHandler(function () {
            //     console.log('>>>EXPAND_COLLAPSE<<<');
            //     this.getSourceBlock().setCollapsed(true);
            // });
        },
        // onchange: function () {
        //     console.log('VMT_CONTROL changed', this);
        // }
    };

Blockly.Blocks['vmt_group'] = {
    init: function () {
        this.jsonInit({
            // "type": "vmt_group",
            "message0": "%1 %2 %3 %4",
            "args0": [
                {
                    "type": "field_image",
                    "name": "EXPAND_COLLAPSE",
                    "src": minusImage,
                    "width": 15,
                    "height": 15,
                    "alt": "+",
                    "tooltip": "Collapse block"
                },
                {
                    "type": "field_input",
                    "name": "GROUP_DESCIPTION",
                    "text": "description"
                },
                {
                    "type": "input_dummy"
                },
                {
                    "type": "input_statement",
                    "name": "GROUP_STATEMENT",
                    "check": ["Brick", "null"]
                }
            ],
            "previousStatement": ["Brick", "null"],
            "nextStatement": ["Brick", "null"],
            "colour": GROUP_COLOR,
            "tooltip": "",
            "helpUrl": ""
        });
        this.getField('EXPAND_COLLAPSE').setOnClickHandler(function () {
            console.log('>>>EXPAND_COLLAPSE<<<');
            this.getSourceBlock().setCollapsed(true);
        });
    }
};

const isValidatorJson = {
    "message0": "%1 %2",
    "args0": [
        {
            "type": "field_dropdown",
            "name": "IS_TYPE",
            "options": [
                [
                    "Is Displayed",
                    "IS_DISPLAYED"
                ],
                [
                    "Is In Viewport",
                    "IS_IN_VIEWPORT"
                ],
                [
                    "Is Enabled",
                    "IS_ENABLED"
                ],
                [
                    "Is Existing",
                    "IS_EXISTING"
                ],
                [
                    "Is Focused",
                    "IS_FOCUSED"
                ],
                [
                    "Is Selected",
                    "IS_SELECTED"
                ]
            ]
        },
        {
            "type": "input_value",
            "name": "EXPECTED_VALUE",
            "check": "Boolean"
        }
    ],
    "inputsInline": true,
    //"output": "Action",
    "nextStatement": "Action",
    "colour": ACTION_VERIFY_COLOR,
    "tooltip": "",
    "helpUrl": ""
};

// NOT IN USE
Blockly.Blocks['is_validator'] = {
    init: function () {
        this.jsonInit({ ...isValidatorJson, "output": "Action" });
    }
};

// NOT IN USE
Blockly.Blocks['is_validator_stack'] = {
    init: function () {
        this.jsonInit({ ...isValidatorJson, "previousStatement": "Action" });
    }
};

function attrGetterValidator(action) {
    const sourceBlock = this.getSourceBlock();
    var attrNameInput = sourceBlock.getInput('ATTRIBUTE_NAME_INPUT');
    // remove field by default
    if (sourceBlock.getField('ATTRIBUTE_NAME')) {
        attrNameInput.removeField('ATTRIBUTE_NAME');
    }
    // add 'ATTRIBUTE_NAME' field when required
    if (action === 'ATTRIBUTE') {
        if (!sourceBlock.getField('ATTRIBUTE_NAME')) {
            attrNameInput.appendField(new Blockly.FieldTextInput('default'), 'ATTRIBUTE_NAME');
            attrNameInput.init();
        }
    }
}

const attrGetterJson = {
    "message0": "%1 %2 ==> %3",
    "args0": [
        {
            "type": "field_dropdown",
            "name": "GETTER_TYPE",
            "options": [
                ['Get Text', 'TEXT'],
                ['Get Value', 'VALUE'],
                ['Get Attribute', 'ATTRIBUTE']
            ]
        },
        {
            "type": "input_dummy",
            "name": "ATTRIBUTE_NAME_INPUT"
        },
        // {
        //     "type": "input_value",
        //     "name": "ATTRIBUTE_NAME",
        //     "check": "String"
        // },
        {
            "type": "field_variable",
            "name": "VAR",
            "variable": ">attr"
        }
    ],
    "inputsInline": true,
    // "previousStatement": "Action",
    // "output": "Action",
    "nextStatement": "Action",
    "colour": ACTION_GETTER_COLOR,
    "tooltip": "",
    "helpUrl": ""
};

Blockly.Blocks['attr_getter'] = {
    init: function () {
        this.jsonInit({ ...attrGetterJson, "output": "Action" });
        this.getField('GETTER_TYPE').setValidator(attrGetterValidator);
    }
};

Blockly.Blocks['attr_getter_stack'] = {
    init: function () {
        this.jsonInit({ ...attrGetterJson, "previousStatement": "Action" });
        this.getField('GETTER_TYPE').setValidator(attrGetterValidator);
    }
};

const expectOpOptions = [
    ["=", "EQ"],
    ["\u2260", "NEQ"],
    ["\u200F<", "LT"],
    ["\u200F\u2264", "LTE"],
    ["\u200F>", "GT"],
    ["\u200F\u2265", "GTE"],
    ["~=", "REGEX"]
];

const expectActionJson = {
    "message0": "%1 %2 %3 %4",
    "args0": [
        {
            "type": "field_dropdown",
            "name": "EXPECT_PROPERTY",
            "options": [
                ['Expect Text', 'TEXT'],
                ['Expect Value', 'VALUE'],
                ['Expect Attribute', 'ATTRIBUTE'],
                ["Expect Displayed", "DISPLAYED"],
                ["Expect In Viewport", "IN_VIEWPORT"],
                ["Expect Enabled", "ENABLED"],
                ["Expect Existing", "EXISTING"],
                ["Expect Focused", "FOCUSED"],
                ["Expect Selected", "SELECTED"]
            ]
        },
        {
            "type": "input_dummy",
            "name": "ATTRIBUTE_NAME_INPUT"
        },
        {
            "type": "field_dropdown",
            "name": "OP",
            "options": expectOpOptions
        },
        {
            "type": "input_value",
            "name": "EXPECTED_VALUE",
            // "check": "Boolean"
        }
    ],
    "inputsInline": true,
    // "previousStatement": "Action",
    // "output": "Action",
    "nextStatement": "Action",
    "colour": ACTION_VERIFY_COLOR,
    "tooltip": "",
    "helpUrl": ""
};

function expectActioValidator(action) {
    const sourceBlock = this.getSourceBlock();
    var attrNameInput = sourceBlock.getInput('ATTRIBUTE_NAME_INPUT');
    const expectedValueInput = sourceBlock.getInput('EXPECTED_VALUE');
    const shadowTextXml = Blockly.Xml.textToDom('<shadow type="text"><field name="TEXT">text</field></shadow>');
    const shadowBoolXml = Blockly.Xml.textToDom('<shadow type="logic_boolean"><field name="BOOL">TRUE</field></shadow>');


    const type1 = 'String';
    const type2 = ['Boolean', 'String'];
    const actionMap = {
        TEXT: { type: type1, attrField: false },
        VALUE: { type: type1, attrField: false },
        ATTRIBUTE: { type: type1, attrField: true },
        DISPLAYED: { type: type2, attrField: false },
        IN_VIEWPORT: { type: type2, attrField: false },
        ENABLED: { type: type2, attrField: false },
        EXISTING: { type: type2, attrField: false },
        FOCUSED: { type: type2, attrField: false },
        SELECTED: { type: type2, attrField: false },
    };

    // remove 'ATTRIBUTE_NAME' field by default
    if (sourceBlock.getField('ATTRIBUTE_NAME')) {
        attrNameInput.removeField('ATTRIBUTE_NAME');
    }

    // add 'ATTRIBUTE_NAME' field when required
    if (actionMap[action].attrField) {
        if (!sourceBlock.getField('ATTRIBUTE_NAME')) {
            attrNameInput.appendField(new Blockly.FieldTextInput('default'), 'ATTRIBUTE_NAME');
            attrNameInput.init();
        }
    }

    // accept all possible types
    expectedValueInput.setCheck(['Boolean', 'String']);

    const shadowXml = actionMap[action].type === 'String' ? shadowTextXml : shadowBoolXml;
    expectedValueInput.setShadowDom(shadowXml);
    expectedValueInput.setCheck(actionMap[action].type);
}

Blockly.Blocks['expect_action'] = {
    init: function () {
        this.jsonInit({ ...expectActionJson, "output": "Action" });
        this.getField('EXPECT_PROPERTY').setValidator(expectActioValidator);
    }
};

Blockly.Blocks['expect_action_stack'] = {
    init: function () {
        this.jsonInit({ ...expectActionJson, "previousStatement": "Action" });
        this.getField('EXPECT_PROPERTY').setValidator(expectActioValidator);
    }
};


Blockly.Blocks['evaluate_brick'] = {
    init: function () {
        this.jsonInit({
            "message0": "%1 %2 %3",
            "args0": [
                {
                    "type": "input_value",
                    "name": "VALUE",
                    "check": ["String", "Boolean", "Number"]
                },
                {
                    "type": "field_dropdown",
                    "name": "OP",
                    "options": expectOpOptions
                },
                {
                    "type": "input_value",
                    "name": "EXPECTED_VALUE",
                    "check": ["Boolean", "String", "Number"]
                }
            ],
            "inputsInline": true,
            "previousStatement": ["Brick", "null"],
            "nextStatement": ["Brick", "null"],
            "colour": ACTION_VERIFY_COLOR,
            "tooltip": "",
            "helpUrl": ""
        });
        //this.getField('EXPECT_PROPERTY').setValidator(expectActioValidator);
    }
};

// validator function for 'action_text' and 'action_text_stack' blocks
function actionTextValidate(action) {
    const sourceBlock = this.getSourceBlock();
    if (action === 'CLEAR_VALUE') {
        sourceBlock.removeInput('TEXT', true);
    }
    else {
        if (!sourceBlock.getInput('TEXT')) {
            const newTextInput = sourceBlock.appendValueInput('TEXT');
            newTextInput.setCheck('String');
            const shadowXml = Blockly.Xml.textToDom('<shadow type="text"><field name="TEXT">text</field></shadow>');
            newTextInput && newTextInput.setShadowDom(shadowXml);
        }
    }
}

const actionTextJson = {
    "message0": "%1 %2 %3",
    "args0": [
        {
            "type": "field_dropdown",
            "name": "ACTION_TYPE",
            "options": [
                [
                    "Set Value",
                    "SET_VALUE"
                ],
                [
                    "Add Value",
                    "ADD_VALUE"
                ],
                [
                    "Clear Value",
                    "CLEAR_VALUE"
                ]
            ]
        },
        {
            "type": "input_dummy"
        },
        {
            "type": "input_value",
            "name": "TEXT",
            "check": "String"
        }
    ],
    "inputsInline": true,
    // "previousStatement": "Action",
    // "output": "Action",
    "nextStatement": "Action",
    "colour": ACTION_INPUT_COLOR,
    "tooltip": "",
    "helpUrl": ""
};

Blockly.Blocks['action_text_stack'] = {
    init: function () {
        this.jsonInit({ ...actionTextJson, "previousStatement": "Action" });
        this.getField('ACTION_TYPE').setValidator(actionTextValidate);
    }
};

Blockly.Blocks['action_text'] = {
    init: function () {
        this.jsonInit({ ...actionTextJson, "output": "Action" });
        this.getField('ACTION_TYPE').setValidator(actionTextValidate);
    }
};

Blockly.Blocks['action_constrained_stack'] = {
    init: function () {
        this.jsonInit({
            "message0": "Constraint %1 %2",
            "args0": [
                {
                    "type": "field_dropdown",
                    "name": "CONST_TYPE",
                    "options": [
                        [
                            "By Index",
                            "BY_INDEX"
                        ],
                        [
                            "By Content",
                            "BY_CONTENT"
                        ]
                    ]
                },
                {
                    "type": "input_value",
                    "name": "CONSTRAINT",
                    "check": "Number"
                }
            ],
            "inputsInline": true,
            "nextStatement": "Action",
            "output": "Action",
            "colour": ACTION_INPUT_COLOR,
            // "style": "math_blocks"
        });

        const field = this.getField('CONST_TYPE');
        field.setValidator(this.validate);
    },

    validate: function (action) {
        const sourceBlock = this.getSourceBlock();
        const constInput = sourceBlock.getInput('CONSTRAINT');
        constInput.setCheck(['Number', 'String']);
        if (action === 'BY_INDEX') {
            const shadowXml = Blockly.Xml.textToDom('<shadow type="math_number"><field name="NUM">0</field></shadow>');
            constInput && constInput.setShadowDom(shadowXml);
            constInput.setCheck('Number');
        }
        else if (action === 'BY_CONTENT') {
            const shadowXml = Blockly.Xml.textToDom('<shadow type="text"><field name="TEXT">text</field></shadow>');
            constInput && constInput.setShadowDom(shadowXml);
            constInput.setCheck('String');
        }
    }
};

Blockly.Blocks['browser_commands'] = {
    init: function () {
        this.jsonInit({
            "message0": "%1 %2 %3",
            "args0": [
                {
                    "type": "field_dropdown",
                    "name": "COMMAND",
                    "options": [
                        [
                            "Browser URL",
                            "BROWSER_URL"
                        ],
                        [
                            "Browser Keys",
                            "BROWSER_KEYS"
                        ],
                        [
                            "Browser New Window",
                            "BROWSER_NEW_WINDOW"
                        ],
                        [
                            "Browser Switch Window",
                            "BROWSER_SWITCH_WINDOW"
                        ],
                        [
                            "Browser Pause (ms)",
                            "BROWSER_PAUSE"
                        ],
                        [
                            "Browser Debug",
                            "BROWSER_DEBUG"
                        ]
                    ]
                },
                {
                    "type": "input_dummy"
                },
                {
                    "type": "input_value",
                    "name": "VALUE",
                    "check": "String"
                }
            ],
            "inputsInline": true,
            "previousStatement": ["Brick", "null"],
            "nextStatement": ["Brick", "null"],
            "colour": ACTION_BROWSER_COLOR,
            "tooltip": "",
            "helpUrl": ""
        });

        const field = this.getField('COMMAND');
        field.setValidator(this.validate);
    },

    validate: function (action) {
        const actionMap = {
            BROWSER_URL: { type: 'String', value: 'https://' },
            BROWSER_KEYS: { type: 'String', value: 'keys' },
            BROWSER_NEW_WINDOW: { type: 'String', value: 'url/window caption' },
            BROWSER_SWITCH_WINDOW: { type: 'String', value: 'url/window caption' },
            BROWSER_PAUSE: { type: 'Number', value: 1000 },
            BROWSER_DEBUG: null
        };

        const sourceBlock = this.getSourceBlock();

        if (actionMap[action]) {
            if (!sourceBlock.getInput('VALUE')) {
                const newTextInput = sourceBlock.appendValueInput('VALUE');
                newTextInput.setCheck(['Number', 'String']);
            }
            const constInput = sourceBlock.getInput('VALUE');
            constInput.setCheck(['Number', 'String']);
            const shadowXmlText = actionMap[action].type === 'Number' ?
                `<shadow type="math_number"><field name="NUM">${actionMap[action].value}</field></shadow>` :
                `<shadow type="text"><field name="TEXT">${actionMap[action].value}</field></shadow>`
            const shadowXml = Blockly.Xml.textToDom(shadowXmlText);
            constInput && constInput.setShadowDom(shadowXml);
            constInput.setCheck(actionMap[action].type);
        }
        else {
            sourceBlock.removeInput('VALUE', true);
        }
    }
};
