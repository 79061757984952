import AppStore from "stores/app";
import TreeStore from "stores/tree";
import AppApi from "apis/app";
import ITreeNode from "types/treeNode";

/*

export const brickDirectory = () => {
    const url = '/brick_dir';
    return {
        [+] get: () => axios.get(url),
        [+] create: (toCreate) => axios.post(`${url}/node`, toCreate),
        [+] update: ({ id, toUpdate }) => axios.patch(`${url}/node/${id}`, toUpdate),
        [+] delete: (nodeIds) => axios.delete(`${url}/nodes`, { params: { nodeIds } }),
        // getOne: ({ id }) => axios.get(`${url}/${id}`),
        // delete: ({ id }) =>  axios.delete(`${url}/${id}`)
    };
};

*/
export default class TreeApi {

    constructor(
        private api: AppApi,
        private store: AppStore,
        private treeStore: TreeStore,
        private readonly url: string) { }

    async getAll() {
        const res = await this.api.client.get(this.url);
        this.treeStore.load(res.data.map((treeNodeJson: any) => treeNodeJson as ITreeNode));
    }

    async create(nodeJson: any): Promise<ITreeNode | null> {
        let node: ITreeNode | null = null;
        try {
            const res = await this.api.client.post(`${this.url}/node`, nodeJson);
            node = res.data.node as ITreeNode;
            this.treeStore.add(node);
        }
        catch { }
        console.log('node', node);
        return node;
    }

    async update(id: string, nodeJson: any): Promise<ITreeNode | null> {
        let node: ITreeNode | null = null;
        try {
            const res = await this.api.client.patch(`${this.url}/node/${id}`, nodeJson);
            node = res.data as ITreeNode;
            this.treeStore.update(node);
        }
        catch { }
        console.log('node', node);
        return node;
    }

    async delete(nodeIds: string[]) {
        let deletedNodes: ITreeNode[] | [] = [];
        try {
            const res = await this.api.client.delete(`${this.url}/nodes`, { params: { nodeIds } });
            deletedNodes = res.data.nodes as ITreeNode[];
            // const updatedNodes = res.data.parents as ITreeNode[];
            this.treeStore.delete(nodeIds);
        }
        catch { }
        console.log('deletedNodes', deletedNodes);
        return deletedNodes;
    }
}