import * as Blockly from 'blockly/core';

Blockly.Blocks['factory_base'] = {

    // Base of new block.
    init: function () {
        this.setColour(120);
        this.appendDummyInput()
            .appendField('Name')
            .appendField(new Blockly.FieldTextInput('block_name'), 'NAME');
        this.appendDummyInput()
            .appendField('Controls');
        this.appendStatementInput('INPUTS')
            .setCheck('control');
        //.appendField('controls');
        this.setTooltip('Build a brick by plugging controls here.');
    },
};

//=============================================================================
//=============================================================================
Blockly.Blocks['control_generic'] = {
    // Dummy input.
    init: function () {
        this.jsonInit({
            "message0": "Control name %1 %2",
            "args0": [
                {
                    "type": "field_input",
                    "name": "INPUT_CONTROL_NAME",
                    "text": "control_name"
                },
                {
                    "type": "input_dummy"
                },
            ],
            // "message1": "ID %1 %2",
            // "args1": [
            //     {
            //         "type": "field_input",
            //         "name": "INPUT_CONTROL_ID",
            //         "text": "control_id"
            //     },
            //     {
            //         "type": "input_dummy"
            //     },
            // ],

            "previousStatement": "control",
            "nextStatement": "control",
            "colour": 210,
            "tooltip": "Enter control's name and tecnical id (XPath, CSS selector etc.)"
            // "helpUrl": "https://www.youtube.com/watch?v=s2_xaEvcVI0#t=293"
        });
    },
    onchange: function () {
        controlNameCheck(this);
    }
};
//=============================================================================
//=============================================================================

/**
 * Check to see if more than one field has this name.
 * Highly inefficient (On^2), but n is small.
 * @param {!Blockly.Block} referenceBlock Block to check.
 */
function controlNameCheck(referenceBlock) {
    console.log('[control_generic] - onchange');
    if (!referenceBlock.workspace) {
        // Block has been deleted.
        return;
    }

    var name = referenceBlock.getFieldValue('INPUT_CONTROL_NAME').toLowerCase();
    // var controlID = referenceBlock.getFieldValue('INPUT_CONTROL_ID');
    // var nameOriginal = referenceBlock.getFieldValue('INPUT_CONTROL_NAME');
    // var name = nameOriginal.toLowerCase();
    // let blockData = {}
    // if (referenceBlock.data){
    //     blockData = JSON.parse(referenceBlock.data);
    // }
    // referenceBlock['data'] = JSON.stringify({...blockData, name: nameOriginal, ID: controlID});

    var count = 0;
    var blocks = referenceBlock.workspace.getAllBlocks();
    for (var i = 0, block; !!(block = blocks[i]); i++) {
        //var otherName = block.getFieldValue('FIELDNAME');
        var otherName = block.getFieldValue('INPUT_CONTROL_NAME');
        if (!block.disabled && !block.getInheritedDisabled() &&
            otherName && otherName.toLowerCase() === name) {
            count++;
        }
    }
    var msg = (count > 1) ?
        'There are ' + count + ' field blocks\n with this name.' : null;
    referenceBlock.setWarningText(msg);
}
