import React, { useState, useEffect, useCallback, memo } from 'react';

// MUI tabs stuff
import PropTypes from 'prop-types';
import MuiTabs from '@mui/material/Tabs';
import MuiTab from '@mui/material/Tab';
import Box from '@mui/material/Box';

// CSS
import { css } from "aphrodite";

// VMT styles
import styles from 'components/Styles';

const a11yProps = (index) => {
    return {
        id: `vmt-tab-${index}`,
        'aria-controls': `vmt-tabpanel-${index}`,
    };
}

const VmtAuxTabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vmt-aux-tabpanel-${index}`}
            aria-labelledby={`vmt-tab-${index}`}
            {...other}
        >
            {children}
        </div>
    );
}

VmtAuxTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export const VmtTabs = memo((props) => {
    const { activeTabIndex = 0, activeTabTitle = '', onSelect = null, children } = props;
    const [tabIndex, setTabIndex] = useState(activeTabIndex);
    const [tabTitles, setTabTitles] = useState([]);
    // const [childs, setChilds] = useState(children);
    
    // console.log('>>>children', children, childs);

    // useEffect(() => {
    //     console.log('tabTitles', tabTitles);
    // }, [tabTitles]);

    useEffect(() => {
        console.log('children', children);
        // setTabTitles([...children.map((comp) => comp.props?.title)]);
        setTabTitles([...React.Children.map(React.Children.toArray(children), (comp) => comp.props?.title)]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [/*children*/]);

    const setActiveTabIndex = useCallback((index) => {
        console.log('setActiveTabIndex', index, tabIndex, tabTitles);
        if (index === tabIndex || index >= tabTitles.length || index > React.Children.toArray(children).length) return;
        setTabIndex(index);
        (typeof onSelect === 'function') && onSelect(tabTitles[index]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabIndex, tabTitles, children]);

    useEffect(() => {
        const newTabIndex = tabTitles.findIndex(title => title === activeTabTitle);
        if (newTabIndex !== -1) { // first priority - activeTabTitle
            setActiveTabIndex(newTabIndex);
        }
        else { // second priority - activeTabIndex
            setActiveTabIndex(activeTabIndex);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTabIndex, activeTabTitle, /*setActiveTabIndex,*/ tabTitles]);

    const handleTabChange = (event, newTabIndex) => {
        console.log('handleTabChange', event, newTabIndex);
        setActiveTabIndex(newTabIndex);
    };

    return (
        <Box sx={{ width: '100%' }}>
            {/* Tabs */}
            <Box className={css(styles.navBar)}>
                <MuiTabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="vmt-tabs"
                >
                    {React.Children.map(React.Children.toArray(children), ((comp, index) => {
                        return (
                            <MuiTab key={`vmt-tab-key-${index}`} label={comp.props?.title} disabled={comp.props?.disabled} {...a11yProps(index)} />
                        )
                    }))}
                </MuiTabs>
            </Box>
            {/* Tab panels */}
            {React.Children.map(React.Children.toArray(children), ((comp, index) => {
                return (
                    <VmtAuxTabPanel key={`vmt-tabpanel-key-${index}`} value={tabIndex} index={index} >
                        {comp}
                    </VmtAuxTabPanel>
                )
            }))}
        </Box>
    );
});

VmtTabs.propTypes = {
    children: PropTypes.node,
    activeTabIndex: PropTypes.number,
    activeTabTitle: PropTypes.string,
    onSelect: PropTypes.func,
};