import React, { useContext, useState } from "react";
import AppApi from "apis/app";
import AppStore from "stores/app";

export const store = new AppStore();
export const api = new AppApi(store);

interface SessionType {
  theme: string;
  setTheme: React.Dispatch<string>;
  layout: string;
  setLayout: React.Dispatch<string>;
}

export const useSessionContext = () => {
  const [theme, setTheme] = useState('dark');
  const [layout, setLayout] = useState('construction');
  return {
    theme,
    setTheme,
    layout,
    setLayout
  } as SessionType;
};


interface AppContextType {
  store: AppStore;
  api: AppApi;
  session: SessionType;
}

const AppContext = React.createContext<null | AppContextType>(null);

export const useAppContext = () => {
  const context = useContext(AppContext);
  return context as AppContextType;
};

export default AppContext;