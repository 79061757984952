import {
    action,
    computed,
    makeObservable,
    observable,
} from 'mobx';

import TreeNode from 'models/treeNode';
import ITreeNode from 'types/treeNode';
import AppStore from 'stores/app';

export default class TreeStore {

    @observable
    nodesById = new Map<string, ITreeNode>()
        .set('', {
            id: '0xFF',
            text: 'Lodaing ...',
            type: 'item'
        } as ITreeNode);

    @observable
    lastUpdatedNodeId = '';

    constructor(private store: AppStore) {
        makeObservable(this);
    }

    @action
    load(nodes: ITreeNode[]) {
        this.nodesById.clear();
        nodes.forEach((node) => this.nodesById.set(node.id, new TreeNode(this.store, node)));
    }

    @action
    add(node: ITreeNode) {
        this.nodesById.set(node.id, new TreeNode(this.store, node));
        const parentNode = this.nodesById.get(node.parent_id);
        parentNode?.children.push(node.id);
        parentNode && this.nodesById.set(parentNode.id, parentNode);
        this.lastUpdatedNodeId = node.id;
    }

    @computed
    get nodes(): ITreeNode[] {
        return Array.from(this.nodesById.values())
    }

    @action
    update(node: ITreeNode) {
        const prevNode = this.nodesById.get(node.id);
        // remove node from the parrent's children array 
        if (prevNode?.parent_id !== node?.parent_id) {
            // previous parent's children array
            const parentNode = this.nodesById.get(prevNode?.parent_id || '');
            const index = parentNode?.children.indexOf(node.id);
            if (index !== undefined && index > -1) {
                parentNode?.children.splice(index, 1);
            }
            // update new parent's children array
            const newParentNode = this.nodesById.get(node.parent_id);
            newParentNode?.children.push(node.id);
            newParentNode && this.nodesById.set(newParentNode.id, newParentNode);
        }
        this.nodesById.set(node.id, new TreeNode(this.store, node));
        this.lastUpdatedNodeId = node.id;
    }

    @action
    delete(ids: string[]) {
        ids.forEach(id => {
            const node = this.nodesById.get(id);
            const parentNode = this.nodesById.get(node?.parent_id || '');
            const index = parentNode?.children.indexOf(id) || -1;
            if (index > -1) {
                parentNode?.children.splice(index, 1);
            }
            this.nodesById.delete(id);
        });
    }
}