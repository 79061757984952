import { useEffect, useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';


// VMT Blockly
import BlocklyJS from 'blockly/javascript';
// import 'blockly/blocks/block_from_LS';
import 'blockly_vmt/generator/generator';


import { Components, URIs } from 'utils/const';

// reactive (RxJS)
import { dataBusStore, useStore } from 'stores';

const ME = Components.LOCAL_RUNNER.key;

const TextStyle = {
    fontFamily: 'monospace',
    fontSize: 'large'
};


const LocalRunner = () => {
    const [isOnline, setIsOnline] = useState(false);
    const [codeToRun, setCodeToRun] = useState(null);
    const socketRef = useRef();

    const [dataBus, setDataBusContent] = useStore(dataBusStore);
    const { enqueueSnackbar/*, closeSnackbar*/ } = useSnackbar();

    const closeSocket = () => {
        if (!!socketRef.current) {
            socketRef.current.close();
        }
    };

    useEffect(() => {
        if (dataBus?.to === ME /*&& dataBus?.content?.brickTree !== null*/) {
            const connectToLocalAgent = () => {
                // Create WebSocket connection.
                socketRef.current = new WebSocket('ws://localhost:1338');

                socketRef.current.onopen = () => {
                    setIsOnline(true);
                    setDataBusContent({
                        to: Components.V_APP.key,
                        from: ME,
                        content: {
                            status: 'Connected'
                        }
                    });
                };

                socketRef.current.onmessage = ({ data }) => {
                    // console.log('>>Received<<', data);
                    setDataBusContent({
                        to: Components.V_APP.key,
                        from: ME,
                        content: {
                            status: 'Running',
                            data: data
                        }
                    });
                };

                socketRef.current.onerror = (event) => {
                    setIsOnline(false);
                    console.log('ERROR', event);
                    setDataBusContent({
                        to: Components.V_APP.key,
                        from: ME,
                        content: {
                            status: 'Error'
                        }
                    });
                    const errMsgJsx = (
                        <div>
                            <div>
                                <Typography sx={TextStyle}>Cannot connect to local agent</Typography>
                            </div>
                            <div>
                                <Link
                                    sx={{ ...TextStyle, color: '#090099' }}
                                    href={URIs.NPM_LocalAgent}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    Click here for installation instructions
                                </Link>
                            </div>
                        </div>
                    );
                    enqueueSnackbar(errMsgJsx, { variant: 'warning', persist: true });
                };

                socketRef.current.onclose = (event) => {
                    setIsOnline(false);
                    setCodeToRun(null);
                    setDataBusContent({
                        to: Components.V_APP.key,
                        from: ME,
                        content: {
                            status: 'Close'
                        }
                    });
                    console.log('Close', event);
                };
            };
            console.log('useEffect [dataBus] (LocalRunner)');
            const dBusConent = dataBus.content;
            if (dBusConent.command === 'Run' && dBusConent.basementWorkspace !== null) {
                // generate code for the current constrcution
                const code = BlocklyJS.workspaceToCode(dBusConent.basementWorkspace);
                setCodeToRun(code);
                connectToLocalAgent();
                // socketRef.current.send(code);
            }
        }
    }, [dataBus, enqueueSnackbar, setDataBusContent]);

    useEffect(() => {
        if (isOnline === true && codeToRun !== null) {
            socketRef.current && socketRef.current.send(codeToRun);
        }
    }, [codeToRun, isOnline]);

    // useEffect(() => {
    //     if (isOnline) {
    //         socketRef.current.send('Here is the stuff to execute');
    //     }
    // }, [isOnline]);

    useEffect(() => {
        console.log("useEffect()");
        //connectToLocalAgent();
        return () => {
            closeSocket();
        };
    }, []);

    return (<></>);
};

export default LocalRunner;