import React, { useState, useEffect, useCallback } from 'react';
import { useSpring, a } from 'react-spring';
import styled from 'styled-components';


const StyledPopupMenu = styled(a.div)`
    position: fixed;
    background: white;
    box-shadow: 0px 2px 10px #999999;
    z-index: 1000;
`;

const PopupMenu = React.memo(({
    posX,
    posY,
    children = [],
    onSelect = () => { } }) => {
    const menuRootRef = React.useRef(null);
    const [isVisible, setVisble] = useState(false);

    const { height, opacity, transform } = useSpring({
        from: { height: 0, opacity: 0, transform: 'translate3d(-20px,0,0)' },
        to: { height: isVisible ? 'auto' : 0, opacity: isVisible ? 1 : 0, transform: `translate3d(${isVisible ? 0 : 20}px,0,0)` }
    });

    // add onClick handler to each child
    let childrenWithHandler = React.Children.map(children, element =>
        React.cloneElement(element, {
            selected: false,
            onClick: (text) => {
                onSelect(text);
                setVisble(false);
            },
        })
    );
    const updatePosition = useCallback((x, y) => {
        const menuElement = menuRootRef.current;

        const parentRect = menuElement.parentNode.getBoundingClientRect();
        const screenW = parentRect.left + parentRect.right; //menuElement.parentNode.offsetWidth + menuElement.parentNode.offsetLeft;
        const screenH = parentRect.top + parentRect.height;//menuElement.parentNode.offsetHeight;
        const rootW = menuElement.offsetWidth;
        const rootH = menuElement.offsetHeight;
        const menuOffset = 5;

        const right = x < (rootW + menuOffset) || (screenW - x) > rootW;
        const left = !right;
        const top = (screenH - y) > rootH;
        const bottom = !top;

        if (right) {
            menuElement.style.left = `${x + menuOffset}px`;
        }

        if (left) {
            menuElement.style.left = `${x - rootW - menuOffset}px`;
        }

        if (top) {
            menuElement.style.top = `${y + menuOffset}px`;
        }

        if (bottom) {
            menuElement.style.top = `${y - rootH - menuOffset}px`;
        }
    }, [menuRootRef]);

    useEffect(() => {
        const root = menuRootRef.current;
        root.style.display = isVisible ? 'block' : 'none';
        isVisible && setTimeout(() => {
            updatePosition(posX, posY);
            root.focus();
        }, 20);

    }, [isVisible, posX, posY, updatePosition]);

    useEffect(() => {
        console.log('useEffect [[posX, posY, menuRootRef]]');
        if (/*menuRootRef.current !== null & */ posX !== -1 && posY !== -1) {
            setVisble(true);
        }
    }, [posX, posY]);

    const handleClick = React.useCallback((event) => {
        const rootElement = menuRootRef.current;
        if (rootElement !== null) {
            const wasOutside = !(rootElement === event.target || rootElement.contains(event.target));
            if (wasOutside) {
                event.stopPropagation();
                setVisble(false);
            }
        }
    }, [menuRootRef, setVisble]);

    const handleScroll = React.useCallback((event) => {
        setVisble(false);
    }, []);

    const onKeyUp = React.useCallback((event) => {
        // if (isVisible) {
        //     console.log(menuRootRef);
        //     console.log(childrenWithHandler);
        //     console.log(childrenWithHandler.map(child => child.props));
        // }

        // if (event.key === 'ArrowDown') {
        //     event.stopPropagation();
        //     console.log(childrenWithHandler[0]);
        //     // childrenWithHandler[0].props.selected = true;
        // }

        if (event.key === 'Escape') {
            event.stopPropagation();
            setVisble(false);
        }
    }, []);

    useEventListener('click', handleClick);
    useEventListener('mousedown', handleClick);
    useEventListener('drag', handleScroll);
    useEventListener('scroll', handleScroll);
    useEventListener('keyup', onKeyUp);

    return (
        <StyledPopupMenu ref={menuRootRef} tabIndex='-1' onKeyUp={onKeyUp} style={{ opacity, height, transform }}>
            {childrenWithHandler}
            {/* {React.Children.map(childrenWithHandler, element => {
                // console.log('el', element);
                return React.cloneElement(element, { ...element, selected: true })
            })} */}
        </StyledPopupMenu>
    );

});

export default PopupMenu;

const StyledMenuItem = styled.div.attrs((/* props */) => ({ tabIndex: 0 }))`
    padding: 6px 50px 5px 10px;
    min-width: 160px;
    cursor: default;
    font-size: 12px;
    &:hover {
        background: linear-gradient(to top, #555, #333);
        color: white;
    }

    &:active {
        color: #e9e9e9;
        background: linear-gradient(to top, #555, #444);
    }

    &.__selected {
        background: linear-gradient(to top, #555, #333);
        color: white;
    }

    &.__disabled {
        color: #999999;
        pointer-events: none;
    }
`;

export const MenuSeparator = styled('div')`
    width: 100%;
    height: 1px;
    background: #CCCCCC;
    margin: 0 0 0 0;
`;

export const MenuItem = ({
    text,
    disabled = false,
    selected = false,
    onClick = () => { } }) => {
    const className = (disabled ? ' __disabled' : '') + (selected ? ' __selected' : '');
    return <StyledMenuItem
        className={className}
        onClick={(event) => {
            event.stopPropagation();
            onClick(text);
        }}
        onKeyDown={(event) => {
            if (disabled) return;
            if (event.keyCode === 13) {
                event.stopPropagation();
                onClick(text);
            }
        }}

    >
        {text}
    </StyledMenuItem>;
};

const useEventListener = (eventName, handler, element = document) => {
    // Create a ref that stores handler
    const savedHandler = React.useRef();

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(() => {
        // Make sure element supports addEventListener
        // On
        const isSupported = element && element.addEventListener;
        if (!isSupported) return;

        // Create event listener that calls handler function stored in ref
        const eventListener = event => savedHandler.current(event);

        // Add event listener
        element.addEventListener(eventName, eventListener);

        // Remove event listener on cleanup
        return () => {
            element.removeEventListener(eventName, eventListener);
        };
    }, [eventName, element]);// Re-run if eventName or element changes
};
