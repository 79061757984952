import AppStore from "stores/app";
import AppApi from "apis/app";
import IBrick from "types/brick";
import IControl from "types/control";

/*

export const bricks = () => {
    const url = '/bricks';
    return {
        // getAll: () => axios.get('https://my-json-server.typicode.com/sampleu/vavanyaAPI/bricks'),
        [+] getAll: () => axios.get(url),
        [+] create: (toCreate) => axios.post(url, toCreate),
        [+] update: ({ id, toUpdate }) => axios.patch(`${url}/${id}`, toUpdate),
        [-] getOne: ({ id }) => axios.get(`${url}/${id}`),
        [-] delete: ({ id }) => axios.delete(`${url}/${id}`)
    };
};

*/
export default class BrickApi {
    private readonly url = '/bricks';

    constructor(private api: AppApi, private store: AppStore) { }

    async getAll() {
        const res = await this.api.client.get(this.url);
        this.store.brick.load(res.data.map((brickJson: any) => this.payloadToBrick(brickJson)));
    }

    async create(brickJson: any) {
        let brick: IBrick | null = null;
        try {
            brickJson.controls = brickJson.controls.map((ctrl: any) => { return { ...ctrl, ID: ctrl.ID ? ctrl.ID : '<api-none>' }; });
            console.log('controls', brickJson.controls);
            const brickPayload: string = JSON.stringify(brickJson);
            console.log('brickPayload', brickPayload);
            const res = await this.api.client.post(this.url, brickPayload);
            brick = this.payloadToBrick(res.data);
            this.store.brick.add(brick);
        }
        catch { }
        console.log('brick', brick);
        return brick;
    }

    async update(id: string, brickJson: any) {
        let brick: IBrick | null = null;
        try {
            const res = await this.api.client.patch(`${this.url}/${id}`, brickJson);
            brick = this.payloadToBrick(res.data);
            // update tree node
            const treeNode = this.store.brickTree.nodes.find(node => node.data?.reference_id === id);
            if (treeNode && treeNode.text !== brick.name) {
                this.api.brickTree.update(treeNode.id, { text: brick.name });
            }
            this.store.brick.update(brick);
        }
        catch { }
        console.log('brick', brick);
        return brick;
    }

    async delete(id: string) {
        let brick: IBrick | null = null;
        try {
            const res = await this.api.client.delete(`${this.url}/${id}`);
            brick = this.payloadToBrick(res.data);
            this.store.brick.update(brick);
        }
        catch { }
        console.log('brick', brick);
        return brick;
    }

    private payloadToBrick(brickJson: any): IBrick {
        const mappedControls = brickJson.controls.map((controlJson: any) => {
            const mappedControl = {
                id: controlJson._id,
                type: controlJson.type,
                name: controlJson.name,
                anchor: controlJson.ID
            };
            return mappedControl as IControl;
        }) || [];

        const mappedBrick = {
            id: brickJson.id,
            name: brickJson.brick_caption,
            type: brickJson.brick_type,
            anchor: 'NOT-IMPL', //brickJson.ID,
            controls: mappedControls as IControl[]
        };
        return mappedBrick as IBrick;
    }
    //   async getById(id: number) {
    //     const res = await this.api.client.get(`/posts/${id}`);
    //     this.store.post.load([res.data]);
    //   }
}