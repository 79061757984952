export const URIs = Object.freeze({
    Default_API: 'https://api.vavanya.com',
    IDP: 'https://myaccount.vavanya.com',
    Fofum: 'https://support.vavanya.com',
    NPM_LocalAgent: 'https://www.npmjs.com/package/@vavanya/local-agent',
    FontAwesome: 'https://use.fontawesome.com/releases/v5.14.0/css/all.css',
});

export const TreeActions = Object.freeze({
    Move: 'move',
    Rename: 'rename',
    Copy: 'move',
    Create: 'create',
    Delete: 'delete'
});

export const CRUD = Object.freeze({
    Create: 'create',
    Read: 'get',
    Update: 'update',
    Delete: 'delete'
});

export const ModeStates = Object.freeze({
    NEW_STATE: 'New',
    EDIT_STATE: 'Edit'
});

export const Types = Object.freeze({
    NODE: 'node',
    ITEM: 'item'
});

export const ContextMenuItems = Object.freeze({
    NewNode: '➕ New Node',
    NewConstruction: '🏗️ New Construction',
    NewBrick: '🧱 New Brick',
    RenameNode: '🎬 Rename',
    DeleteNode: '❌ Delete',
    NodeDetails: '💬 Details...'
});

export const TABS = Object.freeze({
    BRICK_DIR: {
        key: 'BRICK_DIR',
        name: 'Bricks'
    },
    CONSTRUCTION_DIR: {
        key: 'CONSTRUCTION_DIR',
        name: 'Constructions'
    },
    CODE_JS: {
        key: 'CODE_JS',
        name: 'JavaScript'
    },
    LOG: {
        key: 'LOG',
        name: 'Log'
    },
    XML: {
        key: 'XML',
        name: 'XML'
    },
    BRICK_FACTORY: {
        key: 'BRICK_FACTORY',
        name: 'Brick Factory'
    },
});

export const Components = Object.freeze({
    V_APP: {
        key: 'V_APP',
        name: 'VApp'
    },
    LOCAL_RUNNER: {
        key: 'LOCAL_RUNNER',
        name: 'LocalRunner'
    },
    CODE_EDITOR: {
        key: 'CODE_EDITOR',
        name: 'CodePrettifier'
    },
    BRICK_TREE: {
        key: 'BRICK_TREE',
        name: 'BrickTreeComponent'
    },
    BASEMENT: {
        key: 'BASEMENT',
        name: 'BasementComponent'
    },
});

export const InitXmls = Object.freeze({
    WDIO: (testName = 'Test Case') => `
    <xml>
      <block type="base_test" deletable="false" movable="false" x="200" y="100">
      <field name="TEST_NAME">${testName}</field>  
      <statement name="TEST_CONTENT">
          <block type="webdriverio_sync" deletable="false" movable="false"></block>
        </statement>
      </block>
    </xml>`,
    BRICK_FACTORY: () => '<xml><block type="factory_base" deletable="false" movable="false"></block></xml>',
});

export const Colors = Object.freeze({
    // construction block color
    BASE_TEST_COLOR: '#2E476A',
    // WebdriveIO block color
    WDIO_CONFIGURATION_COLOR: '#3E576A',
    // Brick and bricks' controls blocks colors
    BRICK_COLOR: '#444C5C', //'#444C5C',
    CONTROL_COLOR: '#4E576A', //'#4E576A',
    GROUP_COLOR: '#2E476A', //'#4E576A',
    // actions blocks colors
    ACTION_INPUT_COLOR: '#CE5A57',
    ACTION_GETTER_COLOR: '#E1B16A',
    ACTION_VERIFY_COLOR: '#78A5A3',
    // browser actions
    ACTION_BROWSER_COLOR: '#138D90',
});