import Blockly from 'blockly/core';
import 'blockly/javascript';

import { store } from "app-context";
import rawFuncFramework from 'blockly_vmt/generator/func_framework.txt';

let vmtHelperCode = '';

fetch(rawFuncFramework)
    .then(r => r.text())
    .then(text => {
        vmtHelperCode = text;
        // console.log('text decoded:', text);
    });

/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
// introduce 'format' functionality for String class
if (!String.prototype.format) {
    String.prototype.format = function () {
        const args = arguments;
        return this.replace(/{(\d+)}/g, function (match, number) {
            return typeof args[number] != 'undefined'
                ? args[number]
                : match
                ;
        });
    };
}

const vmtBrickPrefix = 'vmt_brick_';

Blockly.JavaScript.addReservedWords('__vmtLastRetVal__');

//========================================================================================
// code generator for the 'vmt_brick' block
Blockly.JavaScript['vmt_brick'] = (block) => {
    const vmtBrickId = JSON.parse(block.data).id;
    const code = Blockly.JavaScript.statementToCode(block, 'CONTROLS');
    if (code === '') return '';

    const brickCode = [
        `// brick id: ${vmtBrickId}`,
        'async () => await brickWrap({',
        `  id: '${block.id}',`,
        '  cbs: [',
        `${code}`,
        '  ]',
        '}),',
        ''
    ].join('\n');
    return brickCode;
};

Blockly.JavaScript['vmt_group'] = (block) => {
    let groupStatementCode = Blockly.JavaScript.statementToCode(block, 'GROUP_STATEMENT');
    if (groupStatementCode === '') return '';

    groupStatementCode = Blockly.JavaScript.prefixLines(groupStatementCode, Blockly.JavaScript.INDENT);

    const groupCode = [
        `// block id: '${block.id}'`,
        'async () => await statementWrap({',
        `  id: '${block.id}',`,
        '  cbs: [',
        `${groupStatementCode}`,
        '  ]',
        '}),',
        ''
    ].join('\n');
    return groupCode;
};


const getControlsInfoFromData = (brick) => {
    // get list of controls for the block
    const childBlocks = brick.getChildren();
    let controlsData = [];
    let controls = [];
    if (childBlocks.length > 0 && childBlocks[0].type.startsWith('vmt_control')) {
        const children = childBlocks[0].getDescendants();
        controls = children.filter(({ type }) => type.startsWith('vmt_control'));
        controls.forEach(control => {
            const controlData = JSON.parse(control.data);
            controlsData.push({ id: controlData.id, anchor: controlData.ID });
        });
    }
    return controlsData;
};

// generate the page object code from the given block type
const getPOCode = (vmtBrickId, brick) => {
    let code = [
        '',
        `// Page object definition for '${vmtBrickPrefix}${vmtBrickId}'`,
        `const ${vmtBrickPrefix}${vmtBrickId} = {`,
        '  /**',
        '   * define elements',
        '   */'
    ].join('\n');

    console.log('GENERATOR', store);
    console.log('GENERATOR', store.brick.bricksById.get(vmtBrickId));

    if (store.brick) {
        const brickObj = store.brick.bricksById.get(vmtBrickId);
        const controls = brickObj !== undefined ? brickObj.controls : getControlsInfoFromData(brick);
        console.log('controls', controls);
        controls && controls.forEach(control => code += `\n  '${control.id}': \`${control.anchor}\`,`)
    }
    code += `\n}; // end of page object definition\n`;
    return code;
}

const wdioImportsCode = [
    '// WebdriverIO',
    `const { remote } = require('webdriverio');`,
    '// workaround for Node v17.x and higher',
    `const dns = require('dns');`,
    '// workaround for Node v17.x and higher',
    `dns.setDefaultResultOrder('ipv4first');`,
    '',
    'const setBrowserCtx = (browser) => browserObject.browser = browser;',
    '',
].join('\n');

//==============================================================================
// Override Blockly.JavaScript.finish method
//==============================================================================
Blockly.JavaScript.finish = function (code) {
    // Add user variables, but only ones that are being used.
    let variables = [];
    if (this.definitions_['variables']) {
        variables = this.definitions_['variables']
            .replaceAll('var', '')
            .replaceAll(';', '')
            .replaceAll(' ', '')
            .split(',');
    }
    console.log('VAR', variables);
    let varsCode = [
        '',
        '// construction\'s variables',
        'const varsObj = {',
        '  __vmtLastRetVal__: undefined,',
        ''
    ].join('\n');
    // Declare all of the variables.
    variables.forEach((variableName) => {
        varsCode += `  ${variableName}: undefined,\n`;
    });
    varsCode += '};\n';

    // delete this.definitions_['variables'];
    // const definitions = utils.object.values(this.definitions_);

    // Call Blockly.Generator's finish.
    code = Object.getPrototypeOf(this).finish.call(this, code);
    this.isInitialized = false;

    this.nameDB_.reset();
    // return definitions.join('\n\n') + '\n\n\n' + code;
    return wdioImportsCode + varsCode + code;
}

//==============================================================================
// Override Blockly.JavaScript.init method
//==============================================================================
// Blockly.JavaScript.init = function (_workspace) {
//jsInitOrig(_workspace);
// Object.getPrototypeOf(this).init.call(this, _workspace);
// Blockly.Generator.prototype.init.call(this, _workspace);
// Blockly.JavaScript.addReservedWords('__vmtLastRetVal__');
// }


//==============================================================================
// Override workspaceToCode method
//==============================================================================
Blockly.JavaScript.workspaceToCode = function (workspace) {
    // workspace code
    const wsCode = Blockly.Generator.prototype.workspaceToCode.call(this, workspace);

    // get VMT blocks for the tests
    const blocks = workspace.getTopBlocks(false);
    let baseTestBlock = blocks.find(block => block.type === 'base_test');
    if (baseTestBlock === undefined) return '';

    const allBlocks = baseTestBlock.getDescendants();

    // generate PageObjects' code
    let buildingBlockCode = ""
    allBlocks.reduce((vmtBricks, block) => {
        if (block.type === 'vmt_brick' && block.isEnabled()) {
            const vmtBrickId = block.data ? JSON.parse(block.data).id : block.type;
            if (!(vmtBrickId in vmtBricks)) {
                vmtBricks[vmtBrickId] = true;
                buildingBlockCode += getPOCode(vmtBrickId, block);
            }
        }
        return vmtBricks;
    }, {});

    // return wdioImportsCode + varsCode + vmtHelperCode + buildingBlockCode + wsCode;
    return /*wdioImportsCode + varsCode +*/ wsCode + buildingBlockCode + vmtHelperCode;
}

Blockly.JavaScript['base_test'] = function (block) {
    const testContent = Blockly.JavaScript.statementToCode(block, 'TEST_CONTENT');
    let testName = block.getFieldValue('TEST_NAME').trim().toLowerCase();
    testName = testName.replace(/\W/g, '_').replace(/^(\d)/, '_$1');

    const constructionCode = [
        '',
        '// a construction definition',
        `const ${testName} = async () => {${testContent}}`,
        '',
        '// call the construction',
        `${testName}().catch(async (ex) => {`,
        '  const browser = await browserObject.browser;',
        '  browser && browser.deleteSession();',
        `  console.log('Failed', ex);`,
        '});',
        ''
    ].join('\n');

    return constructionCode;
};

Blockly.JavaScript['webdriverio_sync'] = function (block) {
    // const configCode = Blockly.JavaScript.statementToCode(block, 'CONFIG');
    const testContent = Blockly.JavaScript.statementToCode(block, 'TEST_CONTENT');
    let configAndContentCode = [
        '',
        'setBrowserCtx(await remote({',
        `  runner: 'local',`,
        '  outputDir: __dirname,',
        '  capabilities: {',
        `    browserName: 'chrome'`,
        '  }',
        '}));',
        '',
        `const result = await statementWrap({ id: '${block.id}', cbs: [`,
        `${testContent}]});`,
        'await browserObject.browser.deleteSession();',
        `console.log(result ? 'Passed' : 'Failed');`,
        ''
    ].join('\n');
    return configAndContentCode;
}

const ACTION_TYPE_CONSTRAINT = 'CONSTRAINT';
const ACTION_TYPE_ACTION = 'ACTION';
// const ACTION_TYPE_GETTER = 'GETTER';
// const ACTION_TYPE_VERIFY = 'VERIFY';

const wrapConstraintCode = (code) => `{"${ACTION_TYPE_CONSTRAINT}": {"Code": ${JSON.stringify(code)}}}`;
const wrapActionCode = (code) => `{"${ACTION_TYPE_ACTION}": {"Code": ${JSON.stringify(code)}}}`;

// const wrapGetterCode = (code) => wrapActionWrapper(`${ACTION_TYPE_GETTER}${ACTION_DELIMETER}${code}`);
// const wrapVerifyCode = (code) => wrapActionWrapper(`${ACTION_TYPE_VERIFY}${ACTION_DELIMETER}${code}`);
//==============================================================================
// Feilds/Controls
//==============================================================================
Blockly.JavaScript['vmt_control'] =
    Blockly.JavaScript['vmt_control_last'] = function (block) {
        let actionsCode = Blockly.JavaScript.valueToCode(block, 'ACTION', Blockly.JavaScript.ORDER_ATOMIC) || '';
        // retreive brick's infromation
        const parentBlock = block.getSurroundParent();
        const vmtBrickId = JSON.parse(parentBlock.data).id;
        const controlId = JSON.parse(block.data).id;
        // generate "base" code for the given control
        const controlScreenProperty = `${vmtBrickPrefix}${vmtBrickId}['${controlId}']`;
        let controlCode = ''
        if (actionsCode !== '') {
            controlCode += `// block id: '${block.id}'\n`;
            const re = /\}\s*\{/g;
            const delimeter = '>>|<<';
            const actionCodePiped = actionsCode.replaceAll(re, `}${delimeter}{`);
            const actions_ = actionCodePiped.split(delimeter);
            const actionsJson = actions_.map(act => JSON.parse(act));
            let selectorCode = '';
            let actCode = '';
            // iterate throught actions stack
            actionsJson.forEach(actionJson => {
                if (actionJson[ACTION_TYPE_CONSTRAINT]) {
                    let constraintCode = actionJson[ACTION_TYPE_CONSTRAINT]['Code'];
                    constraintCode = Blockly.JavaScript.prefixLines(constraintCode, Blockly.JavaScript.INDENT);
                    // selectorCode += `${constraintCode}, `;
                    selectorCode += `\n${constraintCode}\n`;
                }
                else if (actionJson[ACTION_TYPE_ACTION]) {
                    let actionCode = actionJson[ACTION_TYPE_ACTION]['Code'];
                    actionCode = Blockly.JavaScript.prefixLines(actionCode, Blockly.JavaScript.INDENT);
                    actCode += `{\n${actionCode}\n},\n`;
                }
            });
            actCode = Blockly.JavaScript.prefixLines(actCode, Blockly.JavaScript.INDENT + Blockly.JavaScript.INDENT);
            selectorCode = Blockly.JavaScript.prefixLines(selectorCode, Blockly.JavaScript.INDENT);
            controlCode = [
                `// control id ${controlId}`,
                'async () => await controlWrap({',
                `  id: '${block.id}',`,
                `  anchor: ${controlScreenProperty},`,
                `  selector: {${selectorCode}  },`,
                // '  },',
                '  actions: [',
                `${actCode}`,
                '  ]',
                '}),'
            ].join('\n');
            controlCode = Blockly.JavaScript.prefixLines(controlCode, Blockly.JavaScript.INDENT);
        }
        return controlCode;
    };

Blockly.JavaScript['vmt_control_orig'] =
    Blockly.JavaScript['vmt_control_last_orig'] = function (block) {
        let actionsCode = Blockly.JavaScript.valueToCode(block, 'ACTION', Blockly.JavaScript.ORDER_ATOMIC) || '';
        let code = '';
        if (actionsCode !== '') {
            code += `// block id: '${block.id}'\n`;
            code += `controlWrap(\n`;
            console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>vmt_control');
            const re = /\}\s*\{/g;
            // console.log('actionsCode', actionsCode);
            const actionCodePiped = actionsCode.replaceAll(re, '}|{');
            const actions_ = actionCodePiped.split('|');
            const actionsJson = actions_.map(act => JSON.parse(act));
            // console.log('actionsJson',  actionsJson);

            // retreive brick's infromation
            const parentBlock = block.getParent();
            const vmtBrickId = JSON.parse(parentBlock.data).id;
            const controlId = JSON.parse(block.data).id;

            // generate "base" code for the given control
            const controlScreenProperty = `${vmtBrickPrefix}${vmtBrickId}['${controlId}']`;
            let controlIdCode = `vmtProp$({ browser: browserObject.browser, selector: ${controlScreenProperty}`;

            // iterate throught actions stack
            actionsJson.forEach(actionJson => {
                if (actionJson[ACTION_TYPE_CONSTRAINT]) {
                    const constraintCode = actionJson[ACTION_TYPE_CONSTRAINT]['Code'];
                    controlIdCode += `, ${constraintCode}`;
                }
                else if (actionJson[ACTION_TYPE_ACTION]) {
                    const actionCode = actionJson[ACTION_TYPE_ACTION]['Code'];
                    code += `  ${controlIdCode}, ${actionCode} }),\n`;
                    console.log('>> Control code', code);
                }
            })
            code += '),'
        }
        return code;
    };
//==============================================================================
// Actions
//==============================================================================
Blockly.JavaScript['browser_commands'] = function (block) {
    const command = block.getFieldValue('COMMAND');
    const value = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_ATOMIC);
    const commandMap = {
        'BROWSER_URL': 'url',
        'BROWSER_KEYS': 'keys',
        'BROWSER_NEW_WINDOW': 'newWindow',
        'BROWSER_SWITCH_WINDOW': 'switchWindow',
        'BROWSER_PAUSE': 'pause',
        'BROWSER_DEBUG': 'debug',
    };
    const commandCode = [
        `// block id: '${block.id}'`,
        'async () => await commandWrap({',
        `  id: '${block.id}',`,
        `  command: '${commandMap[command]}',`,
        `  args: [${value}]`,
        '}),',
        ''
    ].join('\n');

    return commandCode;
};

Blockly.JavaScript['action_constrained_stack'] = function (block) {
    const const_type = block.getFieldValue('CONST_TYPE');
    const constraint = Blockly.JavaScript.valueToCode(block, 'CONSTRAINT', Blockly.JavaScript.ORDER_ATOMIC);
    let code = `id: '${block.id}',\n`;
    // set constraint
    switch (const_type) {
        case 'BY_INDEX':
            code += `index: ${constraint}`;
            break;
        case 'BY_CONTENT':
            code += `regEx: ${constraint}`;
            break;
        default:
            break;
    }

    return [wrapConstraintCode(code), Blockly.JavaScript.ORDER_ATOMIC];
}

const actionCode = (block) => {
    const action = block.getFieldValue('ACTION');
    let code = `id: '${block.id}',\n`;
    code += 'method: ';
    const actionMap = {
        'CLICK': "'click'",
        'DBL_CLICK': "'doubleClick'",
        'SELECT': "'select'",
        'HOVER': "'moveTo'",
    }
    if (actionMap[action]) {
        code += actionMap[action];
    }
    return code;
};

Blockly.JavaScript['action_type'] = function (block) {
    const code = actionCode(block);
    return [wrapActionCode(code), Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript['action_stack'] = function (block) {
    const code = actionCode(block);
    return wrapActionCode(code);
};

const actionTextCode = (block) => {
    const action = block.getFieldValue('ACTION_TYPE');
    const textValue = Blockly.JavaScript.valueToCode(block, 'TEXT', Blockly.JavaScript.ORDER_ATOMIC);
    let code = `id: '${block.id}',\n`;
    code += 'method: ';
    switch (action) {
        case 'SET_VALUE':
            code += `'setValue', args: [${textValue}]`;
            break;
        case 'ADD_VALUE':
            code += `'addValue', args: [${textValue}]`;
            break;
        case 'CLEAR_VALUE':
            code += `'clearValue'`;
            break;
        default:
            break;
    }
    return code;
};

Blockly.JavaScript['action_text'] = function (block) {
    const code = actionTextCode(block);
    return [wrapActionCode(code), Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript['action_text_stack'] = function (block) {
    const code = actionTextCode(block);
    return wrapActionCode(code);
};

const attrGetterCode = (block) => {
    const getterType = block.getFieldValue('GETTER_TYPE');
    const variableName = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('VAR'), Blockly.VARIABLE_CATEGORY_NAME);
    // TODO: Assemble JavaScript into code variable.
    const methods = {
        'TEXT': 'getText',
        'VALUE': 'getValue',
        'ATTRIBUTE': 'getAttribute'
    };
    let code = `id: '${block.id}',\n`;
    code += `method: '${methods[getterType]}', varRef: '${variableName}'`;
    if (getterType === 'ATTRIBUTE') {
        const attrName = block.getFieldValue('ATTRIBUTE_NAME');
        code += `, args: ['${attrName}']`
    }
    return code;
};

Blockly.JavaScript['attr_getter'] = function (block) {
    const code = attrGetterCode(block);
    return [wrapActionCode(code), Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript['attr_getter_stack'] = function (block) {
    const code = attrGetterCode(block);
    return wrapActionCode(code);
};

const expectActionCode = (block) => {
    const methodMap = {
        'SELECTED': 'isSelected',
        'DISPLAYED': 'isDisplayed',
        'IN_VIEWPORT': 'isDisplayedInViewport',
        'ENABLED': 'isEnabled',
        'EXISTING': 'isExisting',
        'FOCUSED': 'isFocused',
        'TEXT': 'getText',
        'VALUE': 'getValue',
        'ATTRIBUTE': 'getAttribute',
    };
    const expectProperty = block.getFieldValue('EXPECT_PROPERTY');
    const compOperator = block.getFieldValue('OP');
    const expectedValue = Blockly.JavaScript.valueToCode(block, 'EXPECTED_VALUE', Blockly.JavaScript.ORDER_ATOMIC);

    let code = [
        `id: '${block.id}',`,
        `method: '${methodMap[expectProperty]}',`,
        ''
    ].join('\n');

    if (expectProperty === 'ATTRIBUTE') {
        const attrName = block.getFieldValue('ATTRIBUTE_NAME');
        code += `args: ['${attrName}'],\n`;
    }
    code += [
        'expectedResult: {',
        `  operator: '${compOperator}',`,
        `  value: ${expectedValue}`,
        '}'
    ].join('\n');

    return code;
}

Blockly.JavaScript['expect_action'] = function (block) {
    const code = expectActionCode(block);
    return [wrapActionCode(code), Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript['expect_action_stack'] = function (block) {
    const code = expectActionCode(block);
    return wrapActionCode(code);
};

Blockly.JavaScript['evaluate_brick'] = function (block) {
    const value = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_ATOMIC);
    if (value === '') return '';
    const compOperator = block.getFieldValue('OP');
    const expectedValue = Blockly.JavaScript.valueToCode(block, 'EXPECTED_VALUE', Blockly.JavaScript.ORDER_ATOMIC);

    const evaluateCode = [
        `// block id: '${block.id}'`,
        '() => compareWrap({',
        `  id: '${block.id}',`,
        `  value: ${value},`,
        '  expectedResult: {',
        `    operator: '${compOperator}',`,
        `    value: ${expectedValue}`,
        '  }',
        '}),',
        ''
    ].join('\n');
    return evaluateCode;
};

const isValidatorCode = (block) => {
    const methodMap = {
        'IS_DISPLAYED': `'isDisplayed'`,
        'IS_IN_VIEWPORT': `'isDisplayedInViewport'`,
        'IS_ENABLED': `'isEnabled'`,
        'IS_EXISTING': `'isExisting'`,
        'IS_FOCUSED': `'isFocused'`,
        'IS_SELECTED': `'isSelected'`,
    };

    const isType = block.getFieldValue('IS_TYPE');
    const expectedValue = Blockly.JavaScript.valueToCode(block, 'EXPECTED_VALUE', Blockly.JavaScript.ORDER_ATOMIC);

    let methodCode = 'method: ';
    if (methodMap[isType]) {
        methodCode += methodMap[isType];
    }
    let code = `id: '${block.id}',\n`;
    code += `${methodCode}, expectedResult: ${expectedValue}`;
    return code;
};

Blockly.JavaScript['is_validator'] = function (block) {
    const code = isValidatorCode(block);
    return [wrapActionCode(code), Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript['is_validator_stack'] = function (block) {
    const code = isValidatorCode(block);
    return wrapActionCode(code);
};

Blockly.JavaScript['text_concat'] = function (block) {
    const textA = Blockly.JavaScript.valueToCode(block, 'TEXT_A', Blockly.JavaScript.ORDER_ATOMIC);
    const textB = Blockly.JavaScript.valueToCode(block, 'TEXT_B', Blockly.JavaScript.ORDER_ATOMIC);
    const code = `(${textA} + ${textB})`;
    return [code, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

// VMT variable getter
Blockly.JavaScript['vmt_variables_get'] = function (block) {
    var variableName = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('VAR'), Blockly.VARIABLE_CATEGORY_NAME);
    const code = `varsObj['${variableName}']`;
    return [code, Blockly.JavaScript.ORDER_ATOMIC];
}

// const setVariable = () => {
//     return Blockly.JavaScript.provideFunction_(
//         'setVariable',
//         [
//             'const ' + Blockly.JavaScript.FUNCTION_NAME_PLACEHOLDER_ +
//             ' = ({ id, variableName, newValue }) => {',
//             '    return loggerWrap({',
//             '        id, cb: () => {',
//             '            varsObj[variableName] = newValue;',
//             '            Logger.log(`Variable >>${variableName}<< is set to value >>${newValue}<<`);',
//             '            // console.log(`Variable >>${variableName}<< is set to value >>${newValue}<<`);',
//             '            return true;',
//             '        }',
//             '    });',
//             '};',
//         ]);
// }

// VMT variable setter
Blockly.JavaScript['vmt_variables_set'] = function (block) {
    // const functionName = setVariable();
    // Variable setter.
    var argument0 = Blockly.JavaScript.valueToCode(block, 'VALUE', Blockly.JavaScript.ORDER_ASSIGNMENT) || '0';
    var variableName = Blockly.JavaScript.nameDB_.getName(block.getFieldValue('VAR'), Blockly.VARIABLE_CATEGORY_NAME);
    const code = [
        '() => setVariable({',
        `  id: '${block.id}',`,
        `  variableName: '${variableName}',`,
        `  newValue: ${argument0}`,
        '}),',
        ''
    ].join('\n');
    return code;
    //return `() => varsObj['${variableName}'] = ${argument0},\n`;
};

Blockly.JavaScript['action_value_to_statement'] = function (block) {
    const statementCode = Blockly.JavaScript.statementToCode(block, 'STATEMENT') || ' return false; ';
    const code = statementCode.split(';\n').join(' &&\n').replace(/&&\n$/, '\n');
    const header = 'vmtCb2Bool(() => {';
    const footter = '})';
    return [`${header}${code}${footter}`, Blockly.JavaScript.ORDER_ATOMIC];
}

// const vmtIfConditionCode = (statementCode) => {
//     // // const statementCode = statementCodeParam || ' return false; ';
//     // const code = statementCode.split(';\n').join(' &&\n').replace(/&&\n$/, '');
//     // const header = 'vmtCb2Bool(() => { return (\n';
//     // const footter = '\n); })';
//     // return `${header}${code}${footter}`;
//     const conditionCode =
// `await conditionWrap({
//     id: 'CONDITION BLOCK',
//     cbs: [
//     ]
// })`;

// };

const returnStatementWrap = (code) => {
    if (code === '') return '';
    const wrappedCode = [
        'return await statementWrap({ cbs: [',
        `${code}]})`,
        ''
    ].join('\n');
    return Blockly.JavaScript.prefixLines(wrappedCode, Blockly.JavaScript.INDENT);
};

const statementWrap = (code) => {
    if (code === '') return '';
    const wrappedCode = [
        'await statementWrap({ cbs: [',
        `${code}]})`
    ].join('\n');
    return wrappedCode;
    // return Blockly.JavaScript.prefixLines(wrappedCode, Blockly.JavaScript.INDENT);
};

const asyncWrap = (code) => {
    const indentedCode = Blockly.JavaScript.prefixLines(code, Blockly.JavaScript.INDENT);
    const wrappedCode = [
        'async () => {',
        `${indentedCode}`,
        '},',
        ''
    ].join('\n');
    return wrappedCode
};

Blockly.JavaScript['if_statement'] = function (block) {
    // If/elseif/else condition.
    //             actCode = Blockly.JavaScript.prefixLines(actCode, Blockly.JavaScript.INDENT + Blockly.JavaScript.INDENT);

    let n = 0;
    let code = '', branchCode, conditionStatementCode;
    do {
        // conditionCode = vmtIfConditionCode(Blockly.JavaScript.statementToCode(block, 'IF' + n) || 'false');
        conditionStatementCode = Blockly.JavaScript.statementToCode(block, 'IF' + n) || 'false';
        branchCode = Blockly.JavaScript.statementToCode(block, 'DO' + n);
        conditionStatementCode = Blockly.JavaScript.prefixLines(conditionStatementCode, Blockly.JavaScript.INDENT);
        const conditionWrapCode = [
            'await conditionWrap({',
            `  id: '${block.id}',`,
            '  cbs: [',
            `${conditionStatementCode}  ]`,
            '})'
        ].join('\n');

        code += (n > 0 ? ' else ' : '') +
            'if (' + conditionWrapCode + ') {\n' + returnStatementWrap(branchCode) + '}';
        ++n;
    } while (block.getInput('IF' + n));

    if (block.getInput('ELSE')) {
        branchCode = Blockly.JavaScript.statementToCode(block, 'ELSE');
        code += ' else {\n' + returnStatementWrap(branchCode) + '}';
    }

    return asyncWrap(code);
}

Blockly.JavaScript['controls_if'] = function (block) {
    // If/elseif/else condition.
    var n = 0;
    var code = '', branchCode, conditionCode;
    if (Blockly.JavaScript.STATEMENT_PREFIX) {
        // Automatic prefix insertion is switched off for this block.  Add manually.
        code += Blockly.JavaScript.injectId(Blockly.JavaScript.STATEMENT_PREFIX,
            block);
    }
    do {
        conditionCode = Blockly.JavaScript.valueToCode(block, 'IF' + n,
            Blockly.JavaScript.ORDER_NONE) || 'false';
        branchCode = Blockly.JavaScript.statementToCode(block, 'DO' + n);
        if (Blockly.JavaScript.STATEMENT_SUFFIX) {
            branchCode = Blockly.JavaScript.prefixLines(
                Blockly.JavaScript.injectId(Blockly.JavaScript.STATEMENT_SUFFIX,
                    block), Blockly.JavaScript.INDENT) + branchCode;
        }
        code += (n > 0 ? ' else ' : '') +
            'if (' + conditionCode + ') {\n' + returnStatementWrap(branchCode) + '}';
        ++n;
    } while (block.getInput('IF' + n));

    if (block.getInput('ELSE') || Blockly.JavaScript.STATEMENT_SUFFIX) {
        branchCode = Blockly.JavaScript.statementToCode(block, 'ELSE');
        if (Blockly.JavaScript.STATEMENT_SUFFIX) {
            branchCode = Blockly.JavaScript.prefixLines(
                Blockly.JavaScript.injectId(Blockly.JavaScript.STATEMENT_SUFFIX,
                    block), Blockly.JavaScript.INDENT) + branchCode;
        }
        code += ' else {\n' + returnStatementWrap(branchCode) + '}';
    }

    return asyncWrap(code);
};

Blockly.JavaScript['controls_ifelse'] = Blockly.JavaScript['controls_if'];

Blockly.JavaScript['controls_repeat_ext'] = function (block) {
    // Repeat n times.
    let repeats = '';
    if (block.getField('TIMES')) {
        // Internal number.
        repeats = String(Number(block.getFieldValue('TIMES')));
    } else {
        // External number.
        repeats = Blockly.JavaScript.valueToCode(block, 'TIMES',
            Blockly.JavaScript.ORDER_ASSIGNMENT) || '0';
    }
    var branch = Blockly.JavaScript.statementToCode(block, 'DO');
    if (branch === '') return '';

    branch = Blockly.JavaScript.addLoopTrap(branch, block);
    var code = '// repeat\n';
    var loopVar = Blockly.JavaScript.nameDB_.getDistinctName(
        'count', Blockly.VARIABLE_CATEGORY_NAME);
    var endVar = repeats;
    if (!repeats.match(/^\w+$/) && !Blockly.isNumber(repeats)) {
        endVar = Blockly.JavaScript.nameDB_.getDistinctName(
            'repeat_end', Blockly.VARIABLE_CATEGORY_NAME);
        code += 'let ' + endVar + ' = ' + repeats + ';\n';
    }
    code += 'let result = true;\n'
    branch = statementWrap(branch);
    branch = `result = ${branch} && result;\n`
    branch = Blockly.JavaScript.prefixLines(branch, Blockly.JavaScript.INDENT);
    code += 'for (let ' + loopVar + ' = 0; ' +
        loopVar + ' < ' + endVar + '; ' +
        loopVar + '++) {\n' +
        branch + '}\n' +
        'return result;'
    // return code;
    return asyncWrap(code);
};
